import {isDraft} from 'immer';

import * as CustomRunColorsNormalize from './customRunColors/normalize';
import * as DiscussionCommentNormalize from './discussionComment/normalize';
import * as DiscussionThreadNormalize from './discussionThread/normalize';
import * as FilterNormalize from './filter/normalize';
import * as GroupPageNormalize from './groupPage/normalize';
import * as GroupSelectionsNormalize from './groupSelections/normalize';
import * as MarkdownBlockNormalize from './markdownBlock/normalize';
import {
  DenormalizationOptions,
  DenormFunctionMap,
  FullDenormFn,
  FullNormFn,
  lookupPart,
  NormFunctionMap,
  PartWithRef,
  StateType,
} from './normalizerSupport';
import * as PanelNormalize from './panel/normalize';
import * as PanelBankConfigNormalize from './panelBankConfig/normalize';
import * as PanelBankSectionConfigNormalize from './panelBankSectionConfig/normalize';
import * as PanelsNormalize from './panels/normalize';
import * as PanelSettingsNormalize from './panelSettings/normalize';
import * as ProjectPageNormalize from './projectPage/normalize';
import * as ReportNormalize from './report/normalize';
import * as ReportDraftNormalize from './reportDraft/normalize';
import * as RunPageNormalize from './runPage/normalize';
import * as RunSetNormalize from './runSet/normalize';
import * as SectionNormalize from './section/normalize';
import * as SortNormalize from './sort/normalize';
import * as SweepPageNormalize from './sweepPage/normalize';
import * as TempSelectionsNormalize from './tempSelections/normalize';
import * as Types from './types';
import * as WorkspaceSettingsNormalize from './workspaceSettings/normalize';

const normFunctions: NormFunctionMap = {
  'project-view': ProjectPageNormalize.normalize,
  'group-view': GroupPageNormalize.normalize,
  'sweep-view': SweepPageNormalize.normalize,
  'run-view': RunPageNormalize.normalize,
  runs: ReportNormalize.normalize,
  'runs/draft': ReportDraftNormalize.normalize,
  runSet: RunSetNormalize.normalize,
  section: SectionNormalize.normalize,
  'markdown-block': MarkdownBlockNormalize.normalize,
  panels: PanelsNormalize.normalize,
  panel: PanelNormalize.normalize,
  panelSettings: PanelSettingsNormalize.normalize,
  sort: SortNormalize.normalize,
  filters: FilterNormalize.normalize,
  'group-selections': GroupSelectionsNormalize.normalize,
  'run-colors': CustomRunColorsNormalize.normalize,
  'temp-selections': TempSelectionsNormalize.normalize,
  'panel-bank-config': PanelBankConfigNormalize.normalize,
  'panel-bank-section-config': PanelBankSectionConfigNormalize.normalize,
  'discussion-thread': DiscussionThreadNormalize.normalize,
  'discussion-comment': DiscussionCommentNormalize.normalize,
  'workspace-settings': WorkspaceSettingsNormalize.normalize,
};

export function normalize<T extends Types.ObjType>(
  type: T,
  viewID: string,
  whole: Types.ObjSchemaFromType<T>['whole']
): {
  partRef: Types.PartRefFromType<T>;
  partsWithRefs: Array<PartWithRef<Types.ObjType>>;
} {
  const partsWithRefs: Array<PartWithRef<Types.ObjType>> = [];
  const partNormFn = normFunctions[type] as any as FullNormFn<
    Types.ObjSchemaFromType<T>
  >;
  const partRef = partNormFn(whole, {viewID, result: partsWithRefs});
  return {partRef, partsWithRefs};
}

export function addObj<T extends Types.ObjType>(
  partsState: StateType,
  type: T,
  viewID: string,
  whole: Types.ObjSchemaFromType<T>['whole'],
  opts?: {
    // Indicates that whole is an object that is not known to immer.
    // We can freeze it as a performance optimization, which tells immer
    // not to walk it to see if something changed
    wholeIsDefinitelyNew?: boolean;
  }
): Types.PartRefFromType<T> {
  const {wholeIsDefinitelyNew} = opts ?? {};
  const {partRef, partsWithRefs} = normalize(type, viewID, whole);
  for (const {ref, part} of partsWithRefs) {
    partsState[ref.type][ref.id] = wholeIsDefinitelyNew
      ? Object.freeze(part)
      : part;
  }
  return partRef;
}

export function addObjImmutable<T extends Types.ObjType>(
  originalParts: StateType,
  type: T,
  viewID: string,
  whole: Types.ObjSchemaFromType<T>['whole']
): {parts: StateType; ref: Types.PartRefFromType<T>} {
  /* For some reason, the type inference for addObjsImmutable is not working.
   * We know [whole] is the right type here, so it's fine to ignore the type error.
   * This type error is only detected by tsc, not by the linter running in vscode.
   * It's likely that we will be able to remove this when we update to a newer
   * version of TypeScript.
   */
  // @ts-ignore
  const {parts, refs} = addObjsImmutable<T>(originalParts, type, viewID, [
    whole,
  ]);
  return {parts, ref: refs[0]};
}

export function addObjsImmutable<T extends Types.ObjType>(
  originalParts: StateType,
  type: T,
  viewID: string,
  wholes: Types.ObjSchemaFromType<T>['whole'][]
): {parts: StateType; refs: Array<Types.PartRefFromType<T>>} {
  const newParts = Object.assign({}, originalParts);
  const newRefs: Array<Types.PartRefFromType<T>> = [];
  for (const whole of wholes) {
    const {partRef, partsWithRefs} = normalize(type, viewID, whole);
    for (const {ref, part} of partsWithRefs) {
      if (newParts[ref.type] === originalParts[ref.type]) {
        // @ts-ignore
        newParts[ref.type] = Object.assign({}, newParts[ref.type]);
      }
      newParts[ref.type][ref.id] = part;
    }
    newRefs.push(partRef);
  }
  return {parts: newParts, refs: newRefs};
}

export function partExists<R extends Types.AllPartRefs>(
  state: StateType,
  partRef: R
): boolean {
  const partsOfType = state[partRef.type];
  if (partsOfType == null) {
    throw new Error('invalid state');
  }
  return partsOfType[partRef.id] != null;
}

const denormFunctions: DenormFunctionMap = {
  'project-view': ProjectPageNormalize.denormalize,
  'group-view': GroupPageNormalize.denormalize,
  'sweep-view': SweepPageNormalize.denormalize,
  'run-view': RunPageNormalize.denormalize,
  runs: ReportNormalize.denormalize,
  'runs/draft': ReportDraftNormalize.denormalize,
  'markdown-block': MarkdownBlockNormalize.denormalize,
  runSet: RunSetNormalize.denormalize,
  section: SectionNormalize.denormalize,
  panels: PanelsNormalize.denormalize,
  panel: PanelNormalize.denormalize,
  panelSettings: PanelSettingsNormalize.denormalize,
  sort: SortNormalize.denormalize,
  filters: FilterNormalize.denormalize,
  'group-selections': GroupSelectionsNormalize.denormalize,
  'run-colors': CustomRunColorsNormalize.denormalize,
  'temp-selections': TempSelectionsNormalize.denormalize,
  'panel-bank-config': PanelBankConfigNormalize.denormalize,
  'panel-bank-section-config': PanelBankSectionConfigNormalize.denormalize,
  'discussion-thread': DiscussionThreadNormalize.denormalize,
  'discussion-comment': DiscussionCommentNormalize.denormalize,
  'workspace-settings': WorkspaceSettingsNormalize.denormalize,
};

type RefType = Types.PartRefFromType<Types.ObjType>;

interface CacheValue {
  value: {
    whole: Types.WholeFromTypeWithRef<any>;
    partsWithRef: Array<PartWithRef<any>>;
  };
  part: Types.PartFromType<any>;
  state: StateType;
}

const hasWeakMap = 'WeakMap' in globalThis;
const hasWeakRef = 'WeakRef' in globalThis;

class Denormalizer {
  cache: Map<string, WeakRef<CacheValue>>;
  refCache?: WeakMap<RefType, string>;

  constructor() {
    this.cache = new Map();
    this.refCache = hasWeakMap ? new WeakMap<RefType, string>() : undefined;
  }

  getFromRefCache(ref: RefType) {
    if (!this.refCache) {
      return null;
    }
    return this.refCache.get(ref) ?? null;
  }
  setToRefCache(ref: RefType, refString: string) {
    if (!this.refCache) {
      return;
    }
    this.refCache.set(ref, refString);
  }

  refToCacheKey(ref: RefType) {
    const cached = this.getFromRefCache(ref);
    if (cached) {
      return cached;
    }
    // Make the ref's key order canonical for extra paranoia:
    const refString = JSON.stringify({
      id: ref.id,
      type: ref.type,
      viewID: ref.viewID,
    });
    this.setToRefCache(ref, refString);
    return refString;
  }

  getFromCache(ref: RefType) {
    if (hasWeakRef) {
      return this.cache.get(this.refToCacheKey(ref))?.deref();
    }
    return null;
  }

  addToCache(ref: RefType, val: CacheValue) {
    if (hasWeakRef) {
      this.cache.set(this.refToCacheKey(ref), new WeakRef(val));
    }
  }

  getDenormalizeResultFromCache(state: StateType, ref: RefType) {
    // This function is _extremely_ performance-sensitive on large workspaces.
    // Change with caution!
    const cached = this.getFromCache(ref);
    if (!cached) {
      return null;
    }

    // The state values are identical between this and the cached value, which
    // means that we don't need to do any more work. We have the correct, cached
    // normalized value.
    if (state === cached.state) {
      return cached;
    }

    return null;
  }

  generateDenormalizedCacheValue(
    state: StateType,
    ref: RefType,
    opts?: DenormalizationOptions
  ) {
    const partsWithRef: Array<PartWithRef<Types.ObjType>> = [];
    const wholeDenormFn = denormFunctions[ref.type] as FullDenormFn<any>;
    const part = lookupPart(state, ref);
    const whole = wholeDenormFn(ref as any, {state, partsWithRef}, opts);
    const value = {whole, partsWithRef};
    return {
      value,
      part,
      state,
    };
  }

  denormalize(state: StateType, ref: RefType, opts?: DenormalizationOptions) {
    const stateIsDraft = isDraft(state);

    // If the state we get in is an immer draft, _never_ use the cache.
    //
    // This is because there are reducers that will denormalize a value out of
    // the immer draft state, make some mutations to that draft state, then
    // denormalize again. The reference identity of the draft hasn't changed,
    // so the denormalizer erroneously returns the cached value. So if the input
    // is an immer draft, never use the cache because the state value should be
    // treated as mutable.
    //
    // This is also a good encouragement to move stuff out of immer, where
    // possible
    if (stateIsDraft) {
      return this.generateDenormalizedCacheValue(state, ref, opts).value;
    }

    // Pragmatic choice: the only option right now is to exclude refs when
    // denormalizing. The only time this happens is when we send data to the
    // server for saving. This happens infrequently enough that hitting the
    // cache is unnecessary.
    if (opts) {
      return this.generateDenormalizedCacheValue(state, ref, opts).value;
    }

    const fromCache = this.getDenormalizeResultFromCache(state, ref);

    if (fromCache) {
      return fromCache.value;
    }

    // The cache does not contain this ref, either because it has not been
    // added yet, or because it was evicted by the garbage collector. In
    // either case, we need to generate the cache value and add it before
    // returning.
    const cached = this.generateDenormalizedCacheValue(state, ref);
    this.addToCache(ref, cached);
    return cached.value;
  }
}

let cachingDenormalizer: Denormalizer;
function getOrCreateDenormalizer(): Denormalizer {
  if (!cachingDenormalizer) {
    cachingDenormalizer = new Denormalizer();
  }
  return cachingDenormalizer;
}

interface DenormalizeWithPartsResult<T extends Types.ObjType> {
  whole: Types.WholeFromTypeWithRef<T>;
  partsWithRef: Array<PartWithRef<Types.ObjType>>;
}

export function denormalizeWithParts<T extends Types.ObjType>(
  state: StateType,
  ref: Types.PartRefFromType<T>,
  opts?: DenormalizationOptions
): DenormalizeWithPartsResult<T> {
  return getOrCreateDenormalizer().denormalize(
    state,
    ref,
    opts
  ) as DenormalizeWithPartsResult<T>;
}

export function denormalize<T extends Types.ObjType>(
  state: StateType,
  ref: Types.PartRefFromType<T>,
  opts?: DenormalizationOptions
): Types.WholeFromTypeWithRef<T> {
  return denormalizeWithParts(state, ref, opts).whole;
}

import * as globals from '@wandb/weave/common/css/globals.styles';
import {Link} from '@wandb/weave/common/util/links';
import {Icon, IconName} from '@wandb/weave/components/Icon';
import React, {useState} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button, Label, Modal} from 'semantic-ui-react';

import CreateProjectDrawer from '../pages/HomePage/HomePageSidebar/CreateProjectDrawer';
import {useViewer} from '../state/viewer/hooks';
import {useIsAdminModeActiveAndIsNotLocalEnv, viewingAs} from '../util/admin';
import {
  EntityPermissions,
  useViewerHasPermissionForEntity,
} from '../util/entityPermissions';
import {navigateTo} from '../util/history';
import {
  AccessOption,
  AccessOptions,
  getDescriptionForProjectAccess,
  getDisplayNameForProjectAccess,
  getIconForProjectAccess,
  getProjectAccess,
  getTooltipTextForProjectAccess,
} from '../util/permissions';
import {useViewerHasRestrictedProjectView} from '../util/restrictedProjectView';
import * as urls from '../util/urls';
import {useNavContext, useNavUpdaterContext} from './NavContextProvider';
import * as S from './ProjectAccess.styles';

export interface ProjectAccessOptionProps {
  name: string;
  icon: IconName;
  access: AccessOption;
  description: string;
  disabled: boolean;
  warning: boolean;
  default: boolean;
  selected: boolean;
  advanced?: boolean;
  select(): void;
}

export interface ProjectAccessInput {
  projectAccess: AccessOption;
  defaultAccess: AccessOption;
  privateOnly: boolean;
  isTeam: boolean;
  selectAccess(access: AccessOption): void;
}

export function projectAccessOptions(
  input: ProjectAccessInput
): ProjectAccessOptionProps[] {
  const {projectAccess, defaultAccess, privateOnly, isTeam, selectAccess} =
    input;

  const privateOption: ProjectAccessOptionProps = {
    name: getDisplayNameForProjectAccess(AccessOption.Private, isTeam),
    icon: getIconForProjectAccess(AccessOption.Private, isTeam),
    access: AccessOption.Private,
    description: getDescriptionForProjectAccess(AccessOption.Private, isTeam),
    disabled: false,
    warning: false,
    default: defaultAccess === AccessOption.Private,
    selected: projectAccess === AccessOption.Private,
    advanced: false,
    select: () => selectAccess(AccessOption.Private),
  };

  const publicOption: ProjectAccessOptionProps = {
    name: getDisplayNameForProjectAccess(AccessOption.Public, isTeam),
    icon: getIconForProjectAccess(AccessOption.Public, isTeam),
    access: AccessOption.Public,
    description: getDescriptionForProjectAccess(AccessOption.Public, isTeam),
    disabled: isTeam && privateOnly,
    warning: isTeam,
    default: defaultAccess === AccessOption.Public,
    selected: projectAccess === AccessOption.Public,
    advanced: false,
    select: () => selectAccess(AccessOption.Public),
  };

  const openOption: ProjectAccessOptionProps = {
    name: getDisplayNameForProjectAccess(AccessOption.Open, isTeam),
    icon: getIconForProjectAccess(AccessOption.Open, isTeam),
    access: AccessOption.Open,
    description: getDescriptionForProjectAccess(AccessOption.Open, isTeam),
    disabled: isTeam && privateOnly,
    warning: isTeam,
    default: defaultAccess === AccessOption.Open,
    selected: projectAccess === AccessOption.Open,
    advanced: true,
    select: () => selectAccess(AccessOption.Open),
  };

  return [privateOption, publicOption, openOption];
}

export const ProjectAccessOption: React.FC<ProjectAccessOptionProps> =
  React.memo(
    ({
      name,
      icon,
      access,
      description,
      disabled,
      warning,
      default: optionDefault,
      selected,
      select,
    }) => {
      return (
        <S.ProjectAccessModalCheckbox
          radio
          label={
            <label>
              <S.ProjectAccessOptionLabel>
                <Icon name={icon}></Icon>
                <span>{name}</span>
                {warning && <Icon name="warning-alt" color="red" />}
                {optionDefault && <Label color="grey">DEFAULT</Label>}
              </S.ProjectAccessOptionLabel>
              <S.ProjectAccessOptionDescription>
                {description}
              </S.ProjectAccessOptionDescription>
            </label>
          }
          value={access}
          key={access}
          checked={selected}
          disabled={disabled}
          readOnly={disabled}
          onClick={disabled ? undefined : select}
        />
      );
    }
  );

export interface AccessSettings {
  project?: {
    id: string;
    name: string;
    access: AccessOptions;
    user: {
      id: string;
    };
  };
  entity: {
    id: string;
    name: string;
    readOnlyAdmin: boolean;
    defaultAccess: AccessOptions;
    privateOnly: boolean;
    isTeam: boolean;
  };
}

export interface AccessSettingOptionsProps {
  accessSettings: AccessSettings;
  compact?: boolean;
  isEntitySettingsPage?: boolean;
  updateProjectAccess(access: AccessOption): Promise<unknown>;
}

const AccessSettingOptions: React.FC<AccessSettingOptionsProps> = React.memo(
  ({
    accessSettings,
    isEntitySettingsPage: isTeamSettingsPage,
    compact,
    updateProjectAccess,
  }) => {
    const {
      entity: {name: entityName, isTeam, privateOnly, defaultAccess},
    } = accessSettings;

    const {project} = accessSettings;
    const {name: projectName} = project || {};

    const projectAccess = getProjectAccess(
      project?.access ?? AccessOption.Private
    );
    const entityAccess = getProjectAccess(defaultAccess);
    const currentAccess = project ? projectAccess : entityAccess;

    const {isEditingProject} = useNavContext();
    const {toggleEditProjectDrawer} = useNavUpdaterContext();

    const viewer = useViewer();
    const viewingRestricted = useViewerHasRestrictedProjectView({
      projectAccess,
      projectName,
      entityName,
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedAccess, setSelectedAccess] =
      useState<AccessOption>(currentAccess);
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(
      projectAccess === AccessOption.Open
    );
    const [tooltipOpen, setTooltipOpen] = useState(false);

    let accessBadgeText: string;
    let accessBadgeColor: string;
    if (currentAccess === AccessOption.Public) {
      accessBadgeText = 'PUBLIC';
      accessBadgeColor = globals.fern;
    } else if (currentAccess === AccessOption.Open) {
      accessBadgeText = 'OPEN';
      accessBadgeColor = globals.orchid;
    } else if (isTeam) {
      accessBadgeText = 'TEAM';
      accessBadgeColor = globals.aqua;
    } else {
      accessBadgeText = 'PRIVATE';
      accessBadgeColor = globals.cerulean;
    }

    const options = projectAccessOptions({
      projectAccess: selectedAccess,
      defaultAccess: getProjectAccess(defaultAccess),
      privateOnly,
      isTeam,
      selectAccess: (access: AccessOption) => setSelectedAccess(access),
    });

    const currentOption = options.find(
      option => option.access === currentAccess
    );
    const selectedOption = options.find(
      option => option.access === selectedAccess
    );
    const disabled = selectedOption ? selectedOption.disabled : false;

    const {hasPermission: canUpdateProject} = useViewerHasPermissionForEntity(
      entityName,
      EntityPermissions.UpdateProject
    );

    const {hasPermission: canRenameProject} = useViewerHasPermissionForEntity(
      entityName,
      EntityPermissions.RenameProject
    );

    // TODO(elaina): change this when we have project-level roles
    const viewerIsProjectOwner = viewer && project?.user?.id === viewer?.id;
    const editEnabled =
      !viewingRestricted &&
      (canRenameProject || canUpdateProject || viewerIsProjectOwner);

    const onIndicatorClick = () => {
      if (!editEnabled) {
        return;
      }
      setModalOpen(true);
      setTooltipOpen(false);
      setSelectedAccess(currentAccess);
    };

    const onProjectAccessIconClick = () => {
      if (!editEnabled) {
        return;
      }
      toggleEditProjectDrawer();
    };

    const projectInPersonalEntity = !isTeam && viewer?.username === entityName;
    const isAdminMode =
      useIsAdminModeActiveAndIsNotLocalEnv() || viewingAs() !== '';
    const projectAccessIcon = getIconForProjectAccess(currentAccess, isTeam);
    return (
      <>
        {compact ? (
          <>
            <S.TooltipPopup
              open={tooltipOpen}
              offset={3}
              onOpen={() => setTooltipOpen(true)}
              onClose={() => setTooltipOpen(false)}
              position={`top center`}
              popperModifiers={{
                preventOverflow: {
                  boundariesElement: 'viewport',
                },
              }}
              trigger={
                <S.CompactProjectAccessIcon
                  data-test="project-access-indicator"
                  aria-label={`Project access: ${currentAccess}`}
                  className={loading ? 'loading' : ''}
                  onClick={onProjectAccessIconClick}
                  showAdminBanner={isAdminMode}>
                  {projectAccessIcon && <Icon name={projectAccessIcon} />}
                </S.CompactProjectAccessIcon>
              }>
              {getTooltipTextForProjectAccess(currentAccess, isTeam)}
            </S.TooltipPopup>
            {viewer && (
              <CreateProjectDrawer
                open={isEditingProject}
                entityName={entityName}
                editProjectName={projectName}
                toggleProjectDrawer={toggleEditProjectDrawer}
              />
            )}
          </>
        ) : (
          <S.ProjectAccessIconWithLabel
            data-test="project-access-indicator"
            className={loading ? 'loading' : ''}
            $color={accessBadgeColor}
            aria-label={`Project access: ${currentAccess}`}
            size="medium"
            onClick={onIndicatorClick}>
            {currentOption != null && <Icon name={currentOption.icon} />}
            {accessBadgeText}
          </S.ProjectAccessIconWithLabel>
        )}
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Header>
            {isTeamSettingsPage
              ? `Default ${entityName} Project Access`
              : 'Project Access'}
          </Modal.Header>
          <Modal.Content>
            <p>
              Privacy settings affect your whole project, including runs,
              reports, artifacts, etc.
            </p>
            {options
              .filter(o => (showAdvancedSettings ? true : !o.advanced))
              .map(option => {
                return <ProjectAccessOption key={option.access} {...option} />;
              })}
            {!showAdvancedSettings && (
              <S.ShowAdvancedSettingsLink
                onClick={() => setShowAdvancedSettings(true)}>
                + Show advanced settings
              </S.ShowAdvancedSettingsLink>
            )}
            {isTeam && privateOnly && (
              <S.TeamSettingsPrompt>
                To make this project Public or Open, turn off the “Force all
                projects in this team to be private” setting in{' '}
                <Link to={urls.teamSettings(entityName)}>Team Settings</Link>.
              </S.TeamSettingsPrompt>
            )}
            {projectInPersonalEntity && <CreateTeamPrompt />}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setModalOpen(false)}>Cancel</Button>
            <Button
              primary
              disabled={disabled}
              onClick={() => {
                setLoading(true);
                updateProjectAccess(selectedAccess).then(() =>
                  setLoading(false)
                );
                setModalOpen(false);
              }}>
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
);

export default AccessSettingOptions;

const CreateTeamPrompt: React.FC = React.memo(() => {
  return (
    <S.CreateTeamPrompt>
      <S.CreateTeamText>
        Create a team to collaborate on private projects.
      </S.CreateTeamText>
      <S.CreateTeamButton
        primary
        basic
        onClick={() => {
          window.analytics?.track('Create Team Started', {
            location: 'project-settings',
          });
          navigateTo({pathname: '/create-team'});
        }}>
        Create Team
      </S.CreateTeamButton>
    </S.CreateTeamPrompt>
  );
});

import * as Tooltip from '@wandb/weave/components/RadixTooltip';
import classNames from 'classnames';
import React, {
  type CSSProperties,
  type PropsWithChildren,
  type RefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Link} from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';

interface TruncateTextProps {
  fullText: string;
  className?: string;
  isDisabled?: boolean;
  placeholderType?: 'ellipsis' | 'doubleArrow' | 'expand';
  noLink?: boolean;
  linkPath?: string;
  truncationType: TruncationType;
  highlight?: (fullText: string) => React.ReactFragment;
  dataTest?: string;
}

export enum TruncationType {
  Beginning = 'Beginning',
  Middle = 'Middle',
  End = 'End',
}

const truncateEndStyle: CSSProperties = {
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const truncateBeginningStyle: CSSProperties = {
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  direction: 'rtl',
  textAlign: 'left',
};

// Auto-truncates the given text to fit within the width of the containing element.
// Truncates in the middle, with a placeholder (default: ellipsis).
export const TruncateTextTooltip: React.FC<
  PropsWithChildren<TruncateTextProps>
> = ({
  fullText,
  placeholderType = 'ellipsis',
  className,
  noLink,
  linkPath,
  truncationType,
  highlight,
  dataTest,
}) => {
  const {isTextOverflowing, parentDomRef} = useIsTextOverflowing({
    text: fullText,
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {ref: triggerDomRef} = useResizeObserver<HTMLDivElement>();

  const styles =
    truncationType === TruncationType.Beginning
      ? truncateBeginningStyle
      : truncationType === TruncationType.End
      ? truncateEndStyle
      : undefined;

  const highlightedText = highlight ? highlight(fullText) : fullText;

  const PopupTrigger = useMemo(
    () => (
      <div
        ref={triggerDomRef}
        className={isTextOverflowing ? 'isTruncated' : ''}>
        <div className="textStart">{highlightedText}</div>
        <div className={classNames('placeholder', placeholderType)}></div>
        <div className="textEnd">{highlightedText}</div>
      </div>
    ),
    [highlightedText, isTextOverflowing, placeholderType, triggerDomRef]
  );

  return (
    <div
      ref={parentDomRef}
      data-test={dataTest}
      className={
        truncationType === TruncationType.Middle
          ? classNames('TruncateText', className)
          : 'overflow-hidden'
      }>
      <Tooltip.Provider delayDuration={400}>
        <Tooltip.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
          <Tooltip.Trigger asChild>
            {noLink ? (
              <div ref={triggerDomRef} style={styles}>
                {truncationType === TruncationType.Middle ? (
                  PopupTrigger
                ) : (
                  <div ref={triggerDomRef} style={styles}>
                    {highlightedText}
                  </div>
                )}
              </div>
            ) : (
              <Link to={linkPath || ''} className="cursor-pointer">
                {truncationType === TruncationType.Middle ? (
                  PopupTrigger
                ) : (
                  <div ref={triggerDomRef} style={styles}>
                    {highlightedText}
                  </div>
                )}
              </Link>
            )}
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              side="top"
              className="max-w-[400px] overflow-visible whitespace-normal break-words"
              avoidCollisions>
              {fullText}
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </div>
  );
};

export const useIsTextOverflowing = ({
  text,
}: {
  text: string;
}): {
  isTextOverflowing: boolean;
  parentDomRef: RefObject<HTMLDivElement>;
} => {
  const [isTextOverflowing, setIsTextOverflowing] = useState(true);

  const {ref: parentDomRef, width: parentWidth} =
    useResizeObserverWithRef<HTMLDivElement>();

  const textWidth = useTextWidth({text: text.trim(), parentDomRef});

  useLayoutEffect(() => {
    if (parentWidth == null) {
      return;
    }
    setIsTextOverflowing(textWidth > parentWidth);
  }, [parentWidth, text, textWidth]);

  return useMemo(
    () => ({isTextOverflowing, parentDomRef}),
    [isTextOverflowing, parentDomRef]
  );
};

// Returns the width of the given text in pixels, based on the computed font family, size, and weight.
// <canvas> has a built-in measureText function, so this hook creates a temporary canvas element with the same text+fontstyles, and measures that.
const useTextWidth = ({
  text,
  parentDomRef,
}: {
  text: string;
  parentDomRef: RefObject<HTMLDivElement>;
}): number => {
  const [textWidth, setTextWidth] = useState(0);
  const parentElement = parentDomRef.current;

  useEffect(() => {
    if (parentElement == null) {
      return;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context == null) {
      return;
    }

    const {fontSize, fontFamily, fontWeight} = getComputedStyle(parentElement);

    context.font = `${fontWeight} ${fontSize} ${fontFamily}`;
    setTextWidth(Math.ceil(context.measureText(text).width));
  }, [parentElement, text]);

  return textWidth;
};

// useResizeObserver uses a callback ref by default, which means you can't call ref.current to access the dom element.
// This wrapper uses a ref object instead of a callback ref to fix that problem.
const useResizeObserverWithRef = <T extends HTMLElement>() => {
  const ref = useRef<T>(null);
  const {width, height} = useResizeObserver<T>({ref, round: Math.ceil});
  return useMemo(() => ({width, height, ref}), [height, width]);
};

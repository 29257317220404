import {Tag} from '@wandb/weave/common/types/graphql';
import {mediaStrings} from '@wandb/weave/common/types/media';
import {flatten} from '@wandb/weave/common/util/flatten';
import * as StringUtil from '@wandb/weave/common/util/string';
import {formatDurationWithLetters} from '@wandb/weave/common/util/time';
import {JSONNaN} from '@wandb/weave/core';
import produce from 'immer';
import {
  compact,
  floor,
  forEach,
  get,
  isObject,
  isPlainObject,
  isString,
  mapValues,
  omit,
  pickBy,
  union,
} from 'lodash';
import Moment from 'moment';
import numeral from 'numeral';

import {envIsDev} from '../config';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {RunWithRunsetInfo} from '../containers/RunsDataLoader';
import {BenchmarkRun} from '../generated/graphql';
import {captureError} from '../integrations';
import {WithSummary} from '../types/graphql';
import {nonMediaWBValueTypes, RunHistoryRow} from '../types/run';
import {base64DecToArr, base64EncArr} from './b64binary';
import {
  NULL_STRING,
  NULL_STRING_ANGLE_BRACKETS,
  RESERVED_KEYS,
} from './constants';
import * as Parse from './parse';
import {Query} from './queryTypes';
import {
  Aggregations,
  ConfigKeyTree,
  DomValue,
  GROUP_BY_ALL_KEY,
  Key,
  KeyVal,
  LookupKey,
  Run,
  RunKeySection,
  runKeySections,
  Value,
  WBValue,
} from './runTypes';

export const RELATIVE_TIME_SUMMARY_KEY_NAME = '_runtime';

export function summaryKey(k: string) {
  return ['summary', k];
}

// Maps run or group to a unique ID
// Do not change this function! It will change all of the colors
// that users have configured
// TODO BUG (8/16/22): uniqueId is not unique for System Metrics with multiple GPUs
export function uniqueId(
  run: RunWithRunsetInfo,
  groupKeys: Key[],
  includeRunsetId: boolean = true
) {
  let uniqueKey = run.name;
  if (groupKeys.length > 0) {
    // Only include the runsetInfo in the key when we're grouping. This is because
    // we want runs in different runsets to have the same color, but groups
    // should still have different colors. Ideally we would hash the filters or
    // something, but eh.
    uniqueKey =
      (includeRunsetId ? run.runsetInfo.id + '-' : '') +
      groupKeys
        .map(gk => {
          const val = getValue(run, gk);
          const valString = val == null ? 'null' : val.toString();
          return `${keyToString(gk)}:${valString}`;
        })
        .join('-');
  }
  return uniqueKey;
}

export function lookupRunset(run: RunWithRunsetInfo, query: Query) {
  if (query.runSets == null) {
    return undefined;
  }
  return query.runSets.find(rs => rs.id === run.runsetInfo.id);
}

export function groupedRunDisplayName(
  run: RunWithRunsetInfo,
  groupKeys: Key[]
) {
  const groupKeysStr = groupKeys
    .map(gKey => {
      const keyStr = keyDisplayName(gKey);
      const value = run ? getValue(run, gKey) : null;
      const valueStr = value != null ? value.toString() : '';
      return keyStr + ': ' + valueStr;
    })
    .join(' ');
  return groupKeysStr;
}

// Wrap in a summary lookup if not nested
// Warning:  ⚠️
// This is a utility function for benchmarks to make
// lookup simple for summary. They should be migrated to
// use full key lookups.
export const lookupKey = (r: WithSummary<BenchmarkRun>, lk: LookupKey) => {
  const k: string[] = Array.isArray(lk) ? lk : summaryKey(lk);
  return get(r, k);
};

export const flattenLookupKey = (k: LookupKey) => {
  return Array.isArray(k) ? k.toString() : k;
};

export function key(section: RunKeySection, name: string): Key {
  return {section, name};
}

export function keyString(section: RunKeySection, name: string): string {
  return keyToString({section, name});
}

export function configKey(name: string): Key {
  return {section: 'config', name};
}

export function checkKey(section: string, name: string): Key | null {
  if (runKeySections.indexOf(section) === -1) {
    return null;
  }
  return key(section as RunKeySection, name);
}

// _.isEqual is relatively expensive for small objects. this implements a simple
// equals check that runs 100x faster than _.isEqual.
export function keysEqual(keyA: Key, keyB: Key): boolean {
  return keyA.section === keyB.section && keyA.name === keyB.name;
}

export function isAggregationColumn(keyStr: string): boolean {
  return keyStr.startsWith('aggregations_');
}

export function isAggregationSupportedKey(runKey: Key): boolean {
  return ['summary', 'aggregations_min', 'aggregations_max'].includes(
    runKey.section
  );
}

export function filterAggregationKeys(
  aggregationVisible: {[keyName: string]: RunKeySection},
  k: string
): boolean {
  const runKey = keyFromString(k);
  if (runKey && isAggregationSupportedKey(runKey)) {
    // return the summary metric (latest) by default
    const visibleAggregation = aggregationVisible[runKey.name] || 'summary';
    if (visibleAggregation !== runKey.section) {
      return false;
    }
  }
  return true;
}

export function keyFromString(keyStr: string): Key | null {
  let [section, name] = StringUtil.splitOnce(keyStr, ':');
  if (name == null) {
    name = section;
    section = 'run';
  }
  return checkKey(section, name);
}

export function keyFromJSON(json: any): Key | null {
  if (json == null) {
    return null;
  }
  if (!isString(json.section) || !isString(json.name)) {
    return null;
  }
  return checkKey(json.section, json.name);
}

export function keyToString(k: Key): string {
  return k.section + ':' + k.name;
}

export function keyToCss(k: Key): string {
  return keyToString(k).replace(':', '_').replace('.', '_');
}

export const TIME_KEYS = ['run:createdAt', 'run:heartbeatAt'];
export function isTimeKeyString(keyStr: string): boolean {
  return TIME_KEYS.includes(keyStr);
}
export function isTimeKey(k: Key): boolean {
  return isTimeKeyString(keyToString(k));
}

export function formatTimestamp(timestamp: Value): string {
  if (timestamp == null) {
    return NULL_STRING_ANGLE_BRACKETS;
  }
  if (!(typeof timestamp === 'string' || typeof timestamp === 'number')) {
    return `INVALID TIMESTAMP`;
  }
  return Moment(timestamp).format("MMM DD 'YY HH:mm");
}

export const DURATION_KEYS = [`summary:${RELATIVE_TIME_SUMMARY_KEY_NAME}`];
export function isDurationKeyString(k: string): boolean {
  return DURATION_KEYS.includes(k);
}

export function formatDuration(duration: Value): string {
  if (duration == null) {
    return NULL_STRING_ANGLE_BRACKETS;
  }
  if (typeof duration !== 'number') {
    return `INVALID DURATION`;
  }
  return formatDurationWithLetters(duration);
}

export const EmptyAggregations = {min: {}, max: {}};

export function fromJson(json: any): Run | null {
  // Safely parse a json object as returned from the server into a validly typed Run
  // This used to return null in a lot more cases, now if we receive invalid data we
  // set the values to defaults. This happens when we select specific fields from
  // the run in the graphql query (for Scatter and Parallel Coordinates plots). It'd
  // probably be better to have a special type for those cases, instead of using default
  // values, so that other parts of the code has better guarantees about what to expect.
  if (typeof json !== 'object') {
    return null;
  }
  const id = json.id;
  if (typeof id !== 'string' || id.length === 0) {
    // console.warn(`Invalid run id: ${json.id}`);
    return null;
  }

  const name = json.name;
  if (
    typeof name !== 'string' ||
    (name.length === 0 && json.groupCounts == null)
  ) {
    // console.warn(`Invalid run name: ${json.name}`);
    return null;
  }

  const projectId = json.projectId == null ? undefined : Number(json.projectId);

  const outputArtifactsCount = json.outputArtifacts?.totalCount ?? 0;
  const inputArtifactsCount = json.inputArtifacts?.totalCount ?? 0;
  const logLineCount = json.logLineCount;

  let sweep = json.sweep;
  if (sweep == null || typeof sweep.name !== 'string') {
    sweep = undefined;
  }

  let agent = json.agent;
  if (agent == null || typeof agent.name !== 'string') {
    agent = undefined;
  }

  let state = json.state;
  if (typeof state !== 'string' || state.length === 0) {
    state = 'unknown';
  }

  let user = json.user;
  if (user == null || user.username == null || user.photoUrl == null) {
    user = {
      name: '',
      photoUrl: '',
    };
  }

  let host = json.host;
  if (typeof host !== 'string' && host !== null) {
    host = '';
  }

  let github = json.github;
  if (typeof github !== 'string') {
    github = undefined;
  }

  let createdAt = Parse.parseDate(json.createdAt);
  if (createdAt == null) {
    createdAt = new Date();
  }

  let updatedAt = Parse.parseDate(json.updatedAt);
  if (updatedAt == null) {
    updatedAt = new Date(0);
  }

  let heartbeatAt = Parse.parseDate(json.heartbeatAt);
  if (heartbeatAt == null) {
    heartbeatAt = new Date();
  }

  const tags = Array.isArray(json.tags)
    ? json.tags.map((tag: Tag | string) =>
        typeof tag === 'string' ? {name: tag} : tag
      )
    : [];

  let splitConfig: any;
  if (json.config == null) {
    // we don't always pull config. just make it empty
    splitConfig = {config: {}, _wandb: {}};
  } else {
    if (json.groupCounts == null) {
      // When not grouping, config can be deeply nested
      splitConfig = parseConfig(json.config, name);
    } else {
      // When grouping, config is flattened on the server :(
      splitConfig = parseFlatConfig(json.config, name);
    }
    if (splitConfig == null) {
      return null;
    }
  }

  const {_nestedConfigKeys} = splitConfig;

  let systemMetrics = json.systemMetrics;
  try {
    systemMetrics = systemMetrics ? JSONNaN.JSONparseNaN(systemMetrics) : {};
  } catch (e) {
    console.error('Could not parse system metrics');
    systemMetrics = {};
  }

  let summary;
  if (json.summaryMetrics == null) {
    // we don't always pull summary. just make it empty
    summary = {};
  } else {
    summary = parseSummary(json.summaryMetrics, name);
    if (summary == null) {
      return null;
    }
  }

  let aggregations: Aggregations = EmptyAggregations;
  try {
    if (json.aggregations != null) {
      aggregations = json.aggregations;
    }
  } catch {
    console.warn(
      `Couldn't parse aggregations for run ${name}:`,
      json.aggregations
    );
  }

  let wandb;
  if (json.wandbConfig == null) {
    wandb = undefined;
  } else {
    wandb = JSONNaN.JSONparseNaN(json.wandbConfig);
  }

  // 'wandb_version' is an extra thing put in by the CLI that is a misnomer
  // and useless.
  if (splitConfig.config.wandb_version != null) {
    const conf = splitConfig.config as any;
    delete conf.wandb_version;
  }

  return {
    _nestedConfigKeys,
    _wandb: wandb || splitConfig._wandb,
    agent,
    aggregations,
    benchmarkRun: json.benchmarkRun,
    commit: typeof json.commit === 'string' ? json.commit : undefined,
    computeSeconds: json.computeSeconds,
    config: splitConfig.config,
    createdAt: createdAt.toISOString(),
    defaultColorIndex: json.defaultColorIndex,
    displayName: typeof json.displayName === 'string' ? json.displayName : '',
    github,
    group: json.group,
    groupCounts: json.groupCounts,
    heartbeatAt: heartbeatAt.toISOString(),
    historyKeys: json.historyKeys,
    host,
    id,
    inputArtifactsCount,
    jobType: json.jobType,
    logLineCount,
    name,
    notes: typeof json.notes === 'string' ? json.notes : '',
    outputArtifactsCount,
    pendingUpdates: json.pendingUpdates,
    projectId,
    runInfo: json.runInfo,
    sampledHistory: json.sampledHistory?.map((rs: RunHistoryRow[]) =>
      rs.map(unpackMetrics)
    ),
    servicesAvailable: json.servicesAvailable,
    state,
    stopped: json.stopped,
    summary,
    sweep,
    systemMetrics,
    tags,
    updatedAt: updatedAt.toISOString(),
    user,
  };
}

export function splitFlatConfig(flatConfig: KeyVal): {
  config: KeyVal;
  _wandb: KeyVal;
} {
  const wandb: {[key: string]: Value | undefined} = {};
  const config: {[key: string]: Value | undefined} = {};
  forEach(flatConfig, (v, k) => {
    if (k.includes('.desc')) {
      // drop
    } else if (k.startsWith('_wandb.')) {
      wandb[k.substring('_wandb.'.length)] = v;
    } else {
      config[k] = v;
    }
  });
  return {config, _wandb: wandb};
}

export function flattenConfig(config: any) {
  return pickBy(
    removeEmptyObjects(flatten(config, {safe: true})),
    (v, k) => !k.includes('.desc')
  );
}
/**
 * Recursively constructs a ConfigKeyTree from a given nested configuration object.
 * This function traverses the nested structure of the configuration object, omitting
 * specific keys (like '_wandb') and simplifying structures that match a certain pattern
 * (having 'desc' and 'value' as keys). The resulting ConfigKeyTree represents the
 * hierarchical key structure of the original configuration.
 *
 * @param {object} nestedConfig - The nested configuration object to process.
 * @returns {ConfigKeyTree|null} - A tree structure representing the keys of the nested
 *                                 configuration, or null for leaf nodes.
 */
function getNestedConfigKeys(nestedConfig: any): ConfigKeyTree {
  if (!isPlainObject(nestedConfig)) {
    // null represents a leaf node in the key tree
    return null;
  }

  // Omit the '_wandb' key from the configuration object
  nestedConfig = omit(nestedConfig, ['_wandb']);

  const keys = Object.keys(nestedConfig);

  // Simplifies the structure when it contains only 'desc' and 'value' keys, indicating
  // a top-level user config attribute. In such cases, only 'value' is preserved for accessing
  // config values, omitting 'desc' which is metadata. This approach is particularly relevant
  // for sweeps where only the actual parameter values, not the metadata, are required.
  if (keys.length === 2 && keys.includes('desc') && keys.includes('value')) {
    nestedConfig = omit(nestedConfig, ['desc']);
  }

  // Recursively apply the function to the values of the configuration object
  return mapValues(nestedConfig, getNestedConfigKeys);
}

export function parseConfig(
  confJson: any,
  runName: string
): null | {
  config: KeyVal;
  _wandb: KeyVal;
  _nestedConfigKeys: ConfigKeyTree;
} {
  let nestedConfig: any;
  try {
    nestedConfig = JSONNaN.JSONparseNaN(confJson);
  } catch {
    console.warn(`Couldn't parse config for run ${runName}:`, confJson);
    return null;
  }
  if (typeof nestedConfig !== 'object') {
    console.warn(`Invalid config for run ${runName}:`, confJson);
    return null;
  }

  const nestedConfigKeys = getNestedConfigKeys(nestedConfig);

  const flatConfig = removeEmptyObjects(flatten(nestedConfig, {safe: true}));
  const nestedWandb = nestedConfig._wandb;
  const splitConfig = splitFlatConfig(flatConfig);
  const config: KeyVal = {...splitConfig.config, _wandb: nestedWandb};
  const _wandb: KeyVal = splitConfig._wandb;

  return {config, _wandb, _nestedConfigKeys: nestedConfigKeys};
}

function parseFlatConfig(
  confJson: any,
  runName: string
): null | {config: KeyVal; _wandb: KeyVal} {
  let config: any;
  try {
    config = JSONNaN.JSONparseNaN(confJson);
  } catch {
    console.warn(`Couldn't parse flat config for run ${runName}:`, confJson);
    return null;
  }
  if (typeof config !== 'object') {
    console.warn(`Invalid flat config for run ${runName}:`, confJson);
    return null;
  }
  return splitFlatConfig(config);
}

export function cleanSummary(summary: any) {
  // Watch out, when stuff is grouped it comes out flattened, but when it's
  // not it comes out nested. This code works in both cases.
  summary = unflattenWandbObjects(summary);
  const cleanedSummary = removeEmptyObjects(flatten(summary, {safe: true}));
  return cleanedSummary;
}

export function parseSummary(confSummary: any, runName: string): KeyVal | null {
  let summary: any;
  try {
    summary = JSONNaN.JSONparseNaN(confSummary);
  } catch {
    console.warn(`Couldn't parse summary for run ${runName}:`, confSummary);
    return null;
  }
  if (!isObject(summary)) {
    summary = {};
  }
  summary = cleanSummary(summary);
  return unpackMetrics(summary);
}

// Should mirror services/gorilla/history.go#keyBitmapToKeys
export function keyBitmapToKeys(allKeys: string[], bitmap: string): string[] {
  // This is a quick check to make sure that we aren't forgetting to sort the keys before using them with the bitmap
  // it is not perfect and will not catch all cases, but it gives us a chance to detect this problem in dev
  if (allKeys.length > 2 && allKeys[0] > allKeys[1]) {
    if (envIsDev) {
      throw new Error('keyBitmapToKeys: allKeys is not sorted'); // allKeys must be sorted to match the order of keys in bitmap
    }
    console.error('keyBitmapToKeys: allKeys is not sorted');
  }
  // HOT CODE!

  // bitmap is a b64 encoded Uint8Array. Each bit corresponds to a key, where
  // if bit n is 1, then allKeys[n] is present.
  const decodedBitmap = base64DecToArr(bitmap);

  // Pre-count the number of 1s in our bit array so we can preallocate the Array
  // Dynamically growing the Array can be significantly slower
  const arr: string[] = new Array(decodedBitmap.length * 8);

  // Check each bit in order and add any keys that are present
  let insertIdx = 0;
  for (let i = 0; i < decodedBitmap.length; i++) {
    const c = decodedBitmap[i];
    for (let j = 0; j < 8; j++) {
      // 1 << n is a faster way to write Math.pow(2, n) for integer n
      if ((c & (1 << j)) === 0) {
        continue;
      }
      // i << 3 is a faster way to write i * 8
      const idx = (i << 3) + j;
      arr[insertIdx++] = allKeys[idx];
    }
  }

  // Chops the end of the array to the actual length necessary
  arr.length = insertIdx;

  return arr;
}

// used for tests. mirrors services/gorilla/history.go#keysToBitmap
export function keysToBitmap(allKeys: string[], keys: string[]): string {
  keys.sort(); // they should already be sorted, but just in case

  const bc = floor((allKeys.length + 7) / 8);
  const res = new Uint8Array(bc);
  // both allKeys and keys are in order, and keys is a subset of allKeys
  // this means we can just walk allKeys in order and set bits in the bitmap
  // whenever we match the next element in keys
  for (const [idx, key] of allKeys.entries()) {
    if (keys.length === 0) {
      break;
    }
    if (keys[0] !== key) {
      continue;
    }
    const [i, j] = [idx >> 3, idx & 7];
    res[i] |= 1 << j;
    keys = keys.slice(1);
  }
  return base64EncArr(res);
}

function unflattenWandbObjects(summary: {[key: string]: any}) {
  const result: {[key: string]: any} = {};
  Object.entries(summary).forEach(([k, val]) => {
    const [s, last] = StringUtil.splitOnceLast(k, '.');
    if (s == null) {
      if (result[k] == null) {
        result[k] = val;
      }
    } else {
      if (!isObject(result[s])) {
        result[s] = {};
      }
      result[s][last!] = val;
    }
  });
  return result;
}

/**
 * @todo: Please fix this type at some point, it is majorly nested and obfuscated
 */
function removeEmptyObjects(obj: Record<string, any>): Record<string, any> {
  // Flatten will return [] or {} as values. We keys with those values
  // to simplify typing and behavior everywhere else.
  return pickBy(
    obj,
    o => !(isObject(o) && !Array.isArray(o) && Object.keys(o).length === 0)
  );
}

export function getValueSafe(run: Run, runKey: Key): Value {
  // equivalent to getValue but fixes config keys with the weird legacy missing .value
  // like name implies safer than getValue
  if (runKey.section === 'config' && !runKey.name.includes('value')) {
    return getValue(run, fixConfigKey(runKey));
  }

  return getValue(run, runKey);
}

export function getValue(run: Run, runKey: Key): Value {
  // if you are loading a config value and you haven't added the weird .value
  // this will not return a value
  const {section, name} = runKey;

  let val = null;
  if (section === 'run') {
    if (name === 'name') {
      val = run.name;
    } else if (name === 'description') {
      val = run.description || null;
    } else if (name === 'commit') {
      val = run.commit || null;
    } else if (name === 'github') {
      val = run.github || null;
    } else if (name === 'displayName') {
      val = run.displayName;
    } else if (name === 'group') {
      val = run.group;
    } else if (name === 'jobType') {
      val = run.jobType;
    } else if (name === 'username' || name === 'userName') {
      val = run.user.username;
    } else if (name === 'state') {
      val = run.state;
    } else if (name === 'notes') {
      val = run.notes === '' ? '-' : run.notes;
    } else if (name === 'host') {
      val = run.host;
    } else if (name === 'createdAt') {
      val = run.createdAt;
    } else if (name === 'updatedAt') {
      val = run.updatedAt;
    } else if (name === 'heartbeatAt') {
      val = run.heartbeatAt;
    } else if (name === 'duration') {
      val =
        (new Date(run.heartbeatAt).getTime() -
          new Date(run.createdAt).getTime()) /
        1000;
    } else if (name === 'agent' || name === 'agent_id') {
      val = (run.agent && run.agent.name) || null;
    } else if (name === 'sweep' || name === 'sweepName') {
      val = (run.sweep && (run.sweep.displayName ?? run.sweep.name)) || null;
    } else if (name === 'stopped') {
      val = run.stopped;
    } else if (name === 'runInfo.gpu') {
      val = run.runInfo?.gpu ?? null;
    } else if (name === 'runInfo.gpuCount') {
      val = run.runInfo?.gpuCount ?? null;
    }
  } else if (section === 'tags') {
    // Only used for comparing before/after to determine re-render
    val = run.tags.map(t => t.name).join(', ');
  } else if (section === 'config') {
    val = run.config[name];
  } else if (section === 'summary') {
    val = val = run.summary[name];
  } else if (section === 'aggregations_min') {
    val = run.aggregations.min[name];
  } else if (section === 'aggregations_max') {
    val = run.aggregations.max[name];
  }

  return val ?? null;
}

export function getValueFromKeyString(run: Run, keyStr: string): Value {
  const k = keyFromString(keyStr);
  if (k == null) {
    captureError('invalid key', `getValueFromKeyString(${keyStr})`);
    return null;
  }
  return getValue(run, k);
}

export function getTagsString(run: Run): string {
  return run.tags.map(t => t.name).join(', ');
}

// Returns Date types. This should be in getValue but we'd need to go and update
// all the call-sites. So it's split out for now.
export function getValueExtra(run: Run, runKey: Key) {
  const {section, name} = runKey;
  if (section === 'run' && name === 'createdAt') {
    return new Date(run.createdAt);
  } else if (section === 'run' && name === 'updatedAt') {
    return new Date(run.updatedAt);
  } else if (section === 'run' && name === 'heartbeatAt') {
    return new Date(run.heartbeatAt);
  }
  return getValue(run, runKey);
}

const wbValueTypes = union(mediaStrings, nonMediaWBValueTypes);

export function isWBValue(value: Value | undefined): value is WBValue {
  return isObject(value) && wbValueTypes.includes((value as any)._type);
}

export function sortableValue(value: Value) {
  if (typeof value === 'number' || typeof value === 'string') {
    return value;
  } else {
    return JSON.stringify(value);
  }
}

export function valueString(value: Value) {
  if (value == null) {
    return NULL_STRING;
  }
  return value.toString();
}

export function displayKey(k: Key) {
  if (k.section && k.name !== '') {
    if (k.section === 'run') {
      return k.name;
    } else {
      return k.section + ':' + k.name;
    }
  } else {
    return '-';
  }
}

export function prettyNumber(value: number | null | undefined): string {
  if (value == null) {
    return String(value);
  }
  if (!Number.isFinite(value)) {
    return value.toString();
  }
  if (Number.isInteger(value)) {
    return value.toString();
  }
  if (value >= 1 || value <= -1) {
    return numeral(value).format('0.[000]');
  }

  let s = value.toPrecision(4);
  while (s[s.length - 1] === '0') {
    s = s.slice(0, s.length - 1);
  }
  return s;
}

export function displayValue(value: Value, nullVal: string = '-'): string {
  if (value == null) {
    return nullVal;
  } else if (typeof value === 'number') {
    return prettyNumber(value);
  } else if (typeof value === 'string') {
    return value;
  }

  return value.toString();
}

export function domValue(value: Value): DomValue {
  if (typeof value === 'number' || typeof value === 'string') {
    return value;
  }
  if (typeof value === 'boolean') {
    return value.toString();
  }
  return NULL_STRING;
}

export function parseValue(val: any): Value {
  let parsedValue: Value = null;
  if (typeof val === 'number' || typeof val === 'boolean') {
    parsedValue = val;
  } else if (typeof val === 'string') {
    parsedValue = parseFloat(val);
    if (!isNaN(parsedValue)) {
      // If value is '3.' we just get 3, but we return the string '3.' so this can be used in input
      // fields.
      if (parsedValue.toString().length !== val.length) {
        parsedValue = val;
      }
    } else {
      if (val.indexOf('.') === -1) {
        if (val === 'true') {
          parsedValue = true;
        } else if (val === 'false') {
          parsedValue = false;
        } else if (val === NULL_STRING) {
          parsedValue = null;
        } else if (typeof val === 'string') {
          parsedValue = val;
        }
      } else {
        parsedValue = val;
      }
    }
  }
  return parsedValue;
}

export function serverPathToKey(pathString: string): Key | null {
  const [section, name] = StringUtil.splitOnce(pathString, '.');
  if (name == null) {
    return null;
  }
  if (section === 'tags') {
    return {
      section: 'tags',
      name,
    };
  } else if (section === 'config') {
    if (name.includes('.desc')) {
      return null;
    }
    return {
      section: 'config',
      name,
    };
  } else if (section === 'summary_metrics') {
    return {
      section: 'summary',
      name,
    };
  } else if (section === 'aggregations_min') {
    return {
      section: 'aggregations_min',
      name,
    };
  } else if (section === 'aggregations_max') {
    return {
      section: 'aggregations_max',
      name,
    };
  }
  return null;
}

export function serverPathToKeyString(pathString: string): string | null {
  const k = serverPathToKey(pathString);
  if (k == null) {
    return null;
  }
  return keyToString(k);
}

export function keyToServerPath(k: Key): string {
  if (k.section === 'config') {
    return 'config.' + k.name;
  } else if (k.section === 'summary') {
    return 'summary_metrics.' + k.name;
  } else if (k.section === 'keys_info') {
    return 'keys_info.keys.' + k.name;
  } else if (k.section === 'run') {
    return k.name;
  } else if (k.section === 'tags') {
    return 'tags.' + k.name;
  } else if (
    k.section === 'aggregations_min' ||
    k.section === 'aggregations_max'
  ) {
    return k.section + '.' + k.name;
  }
  // Wouldn't need this throw if we use a switch above
  throw new Error('keyToServerPath error');
}

export function keyStringToServerPath(keyStr: string): string | null {
  const k = keyFromString(keyStr);
  if (k == null) {
    return null;
  }
  return keyToServerPath(k);
}

// when sorting keys, we want aggregation keys to be sorted by key name only, not section
// for example, `loss (Min)` should be grouped with other summary keys like `acc` rather
// than before all the config keys
export function compareKeyStrings(a: string, b: string) {
  const sortKeyA = getSortKey(a);
  const sortKeyB = getSortKey(b);
  if (sortKeyA < sortKeyB) {
    return -1;
  }
  if (sortKeyA > sortKeyB) {
    return 1;
  }
  return 0;
}

function getSortKey(keyStr: string): string {
  if (isAggregationColumn(keyStr)) {
    const k = keyFromString(keyStr);
    if (k) {
      return keyString('summary', k.name);
    }
  }
  return keyStr;
}

function shouldAddDotValue(k: Key): boolean {
  return (
    k.section === 'config' &&
    !k.name.includes('.value') &&
    !keysEqual(k, GROUP_BY_ALL_KEY)
  );
}

export function fixConfigKey(k: Key): Key {
  // adds .value to config key
  if (!shouldAddDotValue(k)) {
    return k;
  }
  const splitName = k.name.split('.');
  const splitNameWithValue = [...splitName, 'value'];
  return {
    ...k,
    name: splitNameWithValue.join('.'),
  };
}

export function fixConfigKeyString(ks: string): string {
  const k = keyFromString(ks);
  if (k == null) {
    // punt
    return ks;
  }
  return keyToString(fixConfigKey(k));
}

// For legacy reasons, we need to add ".value" to key strings
// We flatten nested keys by joining them with dots and adding '.value' after the first dot
// e.g. {a: {b: {c: 1}}} => {a.value.b.c: 1},
// For non-nested keys, we add '.value' at the end
// e.g. {a.b.c: 1} => {a.b.c.value: 1}
// If a key is already flat, we don't know if it's actually nested or if it just has dots :(
// So this function generates all possibilities (e.g. key names ['a.value.b.c', 'a.b.value.c', 'a.b.c.value'],
// which we can then test against the run data
export function keyPossibilities(k: Key): Key[] {
  if (!shouldAddDotValue(k)) {
    return [k];
  }
  const splitName = k.name.split('.');

  const keyStringPossibilities: string[] = [];
  for (let i = 0; i < splitName.length; i++) {
    const nestedKeyTest = produce(splitName, draft => {
      draft.splice(i + 1, 0, 'value');
    });
    keyStringPossibilities.push(nestedKeyTest.join('.'));
  }

  return keyStringPossibilities.map(name => ({...k, name}));
}

export const isReservedKey = (k: string) => {
  return RESERVED_KEYS.some(rk => k.startsWith(rk));
};

export function rawConfigKeyDisplayName(k: string): string {
  return k.replace('.value', '');
}

export function keyDisplayName(k: Key, verbose?: boolean): string {
  if (keysEqual(k, GROUP_BY_ALL_KEY)) {
    // LB: I do not understand what this is doing but I am scared to remove it
    return 'Grouped runs';
  }

  if (k.section === 'tags') {
    // Handles the case of "Tags" as a category label in WBTable and Filter dropdown
    return 'Tags';
  } else if (k.section === 'run') {
    switch (k.name) {
      case 'name':
        return 'ID';
      case 'displayName':
        return 'Name';
      case 'userName':
        return 'User';
      case 'username':
        return 'User';
      case 'notes':
        return 'Notes';
      case 'group':
        return 'Group';
      case 'jobType':
        return 'Job Type';
      case 'createdAt':
        if (verbose) {
          return 'Created Timestamp';
        } else {
          return 'Created';
        }
      case 'updatedAt':
        if (verbose) {
          return 'Updated Timestamp';
        } else {
          return 'Updated';
        }
      case 'heartbeatAt':
        if (verbose) {
          return 'Latest Timestamp';
        } else {
          return 'End Time';
        }
      case 'duration':
        return 'Runtime';
      case 'agent':
        return 'Agent';
      case 'stopped':
        return 'Stopped';
      case 'sweep':
        return 'Sweep';
      case 'state':
        return 'State';
      case 'host':
        return 'Hostname';
      case 'description':
        return 'Description';
      case 'commit':
        return 'Commit';
      case 'github':
        return 'GitHub';
      case 'runInfo.gpu':
        return 'GPU Type';
      case 'runInfo.gpuCount':
        return 'GPU Count';
      case 'inputArtifacts':
        return 'Using Artifact';
      case 'outputArtifacts':
        return 'Outputting Artifact';
      default:
        return k.name;
    }
  } else if (k.section === 'config') {
    return rawConfigKeyDisplayName(k.name);
  } else if (k.section === 'summary') {
    if (k.name === RELATIVE_TIME_SUMMARY_KEY_NAME) {
      return 'Relative Time (Process)';
    } else if (k.name === '_timestamp') {
      return 'Wall Time';
    } else if (k.name === '_step') {
      return 'Step';
    }
  } else if (k.section === 'aggregations_min') {
    return k.name.concat(' (Min)');
  } else if (k.section === 'aggregations_max') {
    return k.name.concat(' (Max)');
  }
  return k.name;
}

export function keyStringDisplayName(s: string): string {
  const k = keyFromString(s);
  if (k == null) {
    return s;
  }
  return keyDisplayName(k);
}

// returns a string describing a group of runs, like "15 total runs, 9 filtered, 8 selected. Grouped by ["run:*"] Sorted by: run:createdAt ASC"
export function runsSummaryString(runsSummary: {
  counts?: {runs: number; filtered: number; selected: number};
  grouping?: Key[];
  sort?: {key: Key; ascending: boolean};
}) {
  const {counts, grouping, sort} = runsSummary;
  const countsString =
    counts &&
    `${counts.runs} total runs, ${counts.filtered}
        filtered, ${counts.selected} selected.`;
  const groupingString =
    grouping &&
    grouping.length > 0 &&
    `Grouped by: ${JSON.stringify(grouping.map((k: Key) => keyToString(k)))}`;
  const sortString =
    sort &&
    `Sorted by: ${keyToString(sort.key)} ${sort.ascending ? 'ASC' : 'DESC'}`;
  const summaryString = compact([
    countsString,
    groupingString,
    sortString,
  ]).join(' ');
  return summaryString;
}

export function notes(run: Pick<Run, 'notes'>) {
  if (run.notes && run.notes.length > 0) {
    return run.notes;
  } else {
    return;
  }
}

export function unpackMetrics(metrics: any): any {
  if (metrics == null) {
    return null;
  }
  forEach(metrics, (value, k) => {
    if (isHistogramNode(value)) {
      metrics[k] = getUnpackedHistogram(value);
    }
  });
  return metrics;
}

interface BaseHistogram {
  _type: 'histogram';
  values: number[];
}

export interface UnpackedHistogram extends BaseHistogram {
  bins: number[];
}

interface PackedHistogram extends BaseHistogram {
  packedBins: PackedBins;
}

interface PackedBins {
  min: number;
  size: number;
  count: number;
}

type Histogram = UnpackedHistogram | PackedHistogram;

export const isHistogramNode = (node: unknown): node is Histogram => {
  if (node == null) {
    return false;
  }
  const maybeHisto = node as {
    _type?: unknown;
    values?: unknown;
    bins?: unknown;
    packedBins?: unknown;
  };
  return (
    maybeHisto._type === 'histogram' &&
    maybeHisto.values != null &&
    (maybeHisto.bins != null || maybeHisto.packedBins != null)
  );
};

export const isUnpackedHistogramNode = (
  node: unknown
): node is UnpackedHistogram => {
  if (node == null) {
    return false;
  }
  const maybeUnpackedHistogram = node as {
    bins?: unknown;
  };
  return maybeUnpackedHistogram.bins != null;
};

// This logic should mirror the backend histogram unpack logic in gorilla/pack.go
export function getUnpackedHistogram(h: Histogram): UnpackedHistogram {
  if ('bins' in h) {
    // histogram already unpacked
    return h;
  }

  const bins: number[] = [];
  for (let i = 0; i <= h.packedBins.count; i++) {
    bins.push(h.packedBins.min + i * h.packedBins.size);
  }
  return {
    ...omit(h, 'packedBins'),
    bins,
  };
}

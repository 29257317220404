import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const PageWithSidebar = styled.div`
  display: flex;
  min-height: calc(
    100vh - ${globals.SEARCH_NAV_HEIGHT}
  ); // account for top nav bar
`;
PageWithSidebar.displayName = 'S.PageWithSidebar';

const sidebarBaseStyles = `
  width: 300px;
  padding: 16px 12px;
  background-color: white;
  border-right: 1px solid ${globals.MOON_200};
`;
const sidebarBoxShadow = `0px 12px 24px 0px ${globals.hexToRGB(
  globals.OBLIVION,
  0.12
)}`;

export const InlineSidebar = styled.div<{shadow?: boolean}>`
  ${sidebarBaseStyles}
  position: relative;
  max-width: 300px;
  flex-shrink: 0;

  box-shadow: ${props => (props.shadow ? sidebarBoxShadow : 'none')};
  transition: box-shadow 300ms;

  @media only screen and (max-width: ${globals.MEDIUM_BREAKPOINT}px) {
    min-height: 100%;
    min-width: 100vw;
    border-right: unset;
    border-bottom: 1px solid ${globals.MOON_200};
  }
`;
InlineSidebar.displayName = 'S.InlineSidebar';

export const FloatingSidebar = styled.div<{bodyScrollHeight: number}>`
  ${sidebarBaseStyles}
  min-height: max(
    calc(100vh - ${globals.SEARCH_NAV_HEIGHT}),
    calc(${props => props.bodyScrollHeight}px - ${globals.SEARCH_NAV_HEIGHT})
  );
  height: 100%;
  position: absolute;
  left: 0;
  top: 60px;
  z-index: 999;
  box-shadow: ${sidebarBoxShadow};
  @media only screen and (max-width: ${globals.MEDIUM_BREAKPOINT}px) {
    width: max(300px, 85%);
  }
`;
FloatingSidebar.displayName = 'S.FloatingSidebar';

export const Content = styled.div<{
  background?: string;
}>`
  overflow-x: hidden;
  width: 100%;
  padding: 16px 40px;
  background: ${props =>
    props.background != null ? props.background : 'unset'};

  @media only screen and (max-width: ${globals.MEDIUM_BREAKPOINT}px) {
    padding: 16px 24px;
  }
`;
Content.displayName = 'S.Content';

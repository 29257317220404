import React, {createContext, useContext, useMemo} from 'react';

import {VizMap} from '../../state/graphql/historyKeysQuery';
import {useKeyInfoQuery} from '../../state/runs/hooks';
import * as RunSetTypes from '../../state/views/runSet/types';
import {RunHistoryKeyInfo} from '../../types/run';
import * as RunHelpers from '../../util/runhelpers';

export type KeyInfoQueryResult = {
  loading: boolean;
  error: true | null;
  historyKeyInfo: RunHistoryKeyInfo & {sortedKeys: string[]};
  viz: VizMap;
};

type KeyInfoQueryContext = {
  keyInfoQuery: KeyInfoQueryResult;
  uniqueKeyTypes?: Set<string>;
};
const KeyInfoQueryContext = createContext<KeyInfoQueryContext | undefined>(
  undefined
);

export const KeyInfoQueryContextProvider = ({
  children,
  runSetRefs,
}: {
  children: React.ReactNode;
  runSetRefs: RunSetTypes.Ref[];
}) => {
  const keyInfoQueryResult = useKeyInfoQueryResult(runSetRefs);
  const uniqueKeyTypes = useMemo(() => {
    return makeUniqueKeyTypes(keyInfoQueryResult);
  }, [keyInfoQueryResult]);

  const value = useMemo(
    () => ({
      keyInfoQuery: keyInfoQueryResult,
      uniqueKeyTypes,
    }),
    [keyInfoQueryResult, uniqueKeyTypes]
  );

  return (
    <KeyInfoQueryContext.Provider value={value}>
      {children}
    </KeyInfoQueryContext.Provider>
  );
};
KeyInfoQueryContextProvider.displayName = 'KeyInfoQueryContextProvider';

export const useKeyInfoQueryContext = () => {
  const context = useContext(KeyInfoQueryContext);

  if (!context) {
    throw new Error(
      'useKeyInfoQueryContext must be used within a KeyInfoQueryContextProvider'
    );
  }

  return context;
};

export function useMetricsIncludeHistogram(metrics: string[]) {
  const {keyInfoQuery} = useKeyInfoQueryContext();

  return metrics.some(metric => {
    const typeCounts =
      keyInfoQuery.historyKeyInfo.keys[metric]?.typeCounts?.flatMap(
        t => t.type
      ) ?? [];
    return typeCounts.some(type => type === 'histogram');
  });
}

function makeUniqueKeyTypes(
  keyInfoQuery: KeyInfoQueryResult
): Set<string> | undefined {
  if (keyInfoQuery.loading || keyInfoQuery.error != null) {
    return undefined;
  }

  const {historyKeyInfo} = keyInfoQuery;
  if (historyKeyInfo == null) {
    return undefined;
  }

  const keyTypes = RunHelpers.keyTypes(historyKeyInfo.keys);
  if (keyTypes == null) {
    return undefined;
  }
  return new Set<string>(Object.values(keyTypes) as string[]);
}

const useKeyInfoQueryResult = (
  runSetRefs: RunSetTypes.Ref[]
): KeyInfoQueryResult => {
  const keyInfoQuery = useKeyInfoQuery(runSetRefs);

  const isKeyInfoLoading = keyInfoQuery.loading;
  const keyInfoQueryError = keyInfoQuery.error;

  const historyKeyInfo = useMemo(() => {
    if (isKeyInfoLoading || keyInfoQueryError != null) {
      return {sets: [], keys: {}, sortedKeys: []};
    }
    return Object.assign({}, keyInfoQuery.historyKeyInfo, {
      /**
       * later on we need the keys sorted. Sorting keys (that might number in the 10k+ range)
       * can get expensive so we do it up top to take the hit once and not risk calling it
       * multiple times in a downstream loop
       */
      sortedKeys: Object.keys(keyInfoQuery.historyKeyInfo.keys).sort(),
    });
  }, [isKeyInfoLoading, keyInfoQuery, keyInfoQueryError]);
  const viz = useMemo(() => {
    if (isKeyInfoLoading || keyInfoQueryError != null) {
      return {};
    }
    return keyInfoQuery.viz;
  }, [isKeyInfoLoading, keyInfoQuery, keyInfoQueryError]);

  return useMemo(() => {
    return {
      loading: isKeyInfoLoading,
      error: keyInfoQueryError,
      historyKeyInfo,
      viz,
    };
  }, [historyKeyInfo, viz, isKeyInfoLoading, keyInfoQueryError]);
};

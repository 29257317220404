import {debounce} from 'lodash';
import React, {useCallback, useMemo} from 'react';
import {twMerge} from 'tailwind-merge';

import {globalXAxisOptions} from '../../../util/runhelpers';
import {useKeyInfoQueryContext} from '../../MultiRunWorkspace/KeyInfoQueryContext';
import {ClearSettingsRow} from './ClearSettingsRow';
import {DEFAULT_X_AXIS_OPTION, DEFAULT_X_AXIS_OPTION_VALUES} from './defaults';
import {NumberRangeInput} from './NumberRangeInput';
import {SettingLabel} from './SettingLabel';
import {SettingSelect} from './SettingSelect';
import {LinePlotSettings, SettingSelectOptionType} from './types';
import {xAxisLabel} from './utils';

export type LinePlotXAxisProps = {
  clearSetting?: () => void;
  numOverrides: number;
  overrideType?: 'section' | 'panel';
  type: 'workspace' | 'section' | 'report';
  updateSetting: (settings: Partial<LinePlotSettings>) => void;
  xAxis?: string;
  xAxisMax?: number;
  xAxisMin?: number;
  xAxisOptions: SettingSelectOptionType[];
};

export const LinePlotXAxis = ({
  clearSetting,
  numOverrides,
  overrideType,
  type,
  updateSetting,
  xAxis,
  xAxisMax,
  xAxisMin,
  xAxisOptions,
}: LinePlotXAxisProps) => {
  const xAxisValue = useMemo(() => {
    // X axis options in a workspace vs reports are different.
    // If a user tries to create a report from the workspace page,
    // we need to check if the currently selected xAxis is valid in reports.
    const isValidXAxis = (xAxisOptions ?? []).some(opt => opt.value === xAxis);
    if (isValidXAxis && xAxis != null) {
      return {value: xAxis, label: xAxisLabel(xAxis)};
    }
    return DEFAULT_X_AXIS_OPTION;
  }, [xAxis, xAxisOptions]);

  const onXAxisChange = useCallback(
    (selected: SettingSelectOptionType | null) => {
      if (selected != null) {
        updateSetting({
          xAxis: selected.value,
        });
      }
    },
    [updateSetting]
  );

  return (
    <div className={twMerge('flex flex-col', 'gap-12')}>
      <div className="flex">
        <SettingLabel text="X axis" />
        <div className="w-[20rem] grow">
          <SettingSelect
            options={xAxisOptions}
            value={xAxisValue}
            onChange={onXAxisChange}
          />
        </div>
      </div>
      <div className="flex">
        <div
          className={twMerge(
            'flex flex-col',
            'border-l-2 border-moon-350',
            'mx-12'
          )}
        />
        <div className="flex flex-col gap-12">
          <XAxisRange
            xAxisMin={xAxisMin}
            xAxisMax={xAxisMax}
            updateSetting={updateSetting}
          />
          <ClearSettingsRow
            count={numOverrides}
            type={type}
            overrideType={overrideType}
            clearSetting={clearSetting}
          />
        </div>
      </div>
    </div>
  );
};

export const XAxisRange = ({
  xAxisMin,
  xAxisMax,
  updateSetting,
}: {
  xAxisMin?: number;
  xAxisMax?: number;
  updateSetting: (settings: Partial<LinePlotSettings>) => void;
}) => {
  const debouncedUpdateSetting = debounce(updateSetting, 500);
  return (
    <div className="flex">
      <SettingLabel text="Range" className="w-[87px]" />
      <div className="flex gap-8">
        <NumberRangeInput
          minValue={xAxisMin}
          maxValue={xAxisMax}
          updateSetting={debouncedUpdateSetting}
        />
      </div>
      {/* TODO - add log scale button */}
    </div>
  );
};

export const useWorkspaceXAxisOptions = () => {
  const {
    keyInfoQuery: {historyKeyInfo},
  } = useKeyInfoQueryContext();
  const historyKeyXAxis = useMemo(
    () => globalXAxisOptions(historyKeyInfo),
    [historyKeyInfo]
  );

  const xValues = useMemo(() => {
    return DEFAULT_X_AXIS_OPTION_VALUES.concat(historyKeyXAxis).map(val => ({
      label: xAxisLabel(val),
      value: val,
    }));
  }, [historyKeyXAxis]);

  return xValues;
};

// Reports support multiple runsets, so default options
export const getReportXAxisOptions = (): SettingSelectOptionType[] => {
  return DEFAULT_X_AXIS_OPTION_VALUES.map(val => ({
    label: xAxisLabel(val),
    value: val,
  }));
};

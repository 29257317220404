import {useMemo} from 'react';
import {create} from 'zustand';

import {TooltipNumberOfRunsOptions} from '../../components/WorkspaceDrawer/Settings/types';
import {
  POINT_VISUALIZATION_OPTIONS,
  PointVisualizationOptions,
} from '../../components/WorkspaceSettingsModal/controls/PointVisualizationTypes';
import {OrganizationPrefix} from './../../components/PanelBank/types';

// defaults are set from https://wandb.atlassian.net/browse/WB-17958
export const DefaultWorkspaceSettings = {
  pointVisualizationMethod: POINT_VISUALIZATION_OPTIONS.SamplingByDefault,

  // sections
  showEmptySections: false,
  autoOrganizePrefix: OrganizationPrefix.FirstPrefix,
  sortAlphabetically: false,

  // tooltips
  tooltipNumberOfRuns: TooltipNumberOfRunsOptions.Default,
  displayFullRunName: false,
};

export type WorkspaceSettings = {
  // sections
  showEmptySections: boolean;
  autoOrganizePrefix: OrganizationPrefix;
  sortAlphabetically: boolean;

  // tooltips
  tooltipNumberOfRuns: TooltipNumberOfRunsOptions; // controls how many runs show in the tooltips
  displayFullRunName: boolean;
};

export type DefaultedWorkspaceSettings = {
  pointVisualizationMethod?: PointVisualizationOptions; // controls if a user is bucketing w/ weave or using legacy sampling
};

export type CombinedWorkspaceSettings = WorkspaceSettings &
  DefaultedWorkspaceSettings;

/**
 * Editable Write vs Editable Read
 * The types here are messier than I'd like, but the problem is that we want to discourage reading certain values off of the zustand store. The reason is that those values can only be safely read in context with other workspace settings that zustand is unaware of. E.g. the number of maxRuns allowed is different at the time of this comment between bucketing and sampling in order to safeguard the API from known performance issues with overly expansive bucketing queries.
 *
 * That said, those values can still be _written_ because of the way that data flows between the workspace spec and zustand (writes to the spec, which come in as props and get written to zustand, and then the reads either come from zustand OR from specific hooks like useMaxRuns which access zustand in conjunction with other app context)
 */
export type EditableWorkspaceSettingsWrite = Omit<
  CombinedWorkspaceSettings,
  'deprecatedMaxRuns'
>;
export type EditableWorkspaceSettingsRead = Omit<
  EditableWorkspaceSettingsWrite,
  'maxRuns' | 'pointVisualizationMethod'
>;

export type WorkspaceSettingsUpdaters = {
  // whenever a workspace unmounts we need to clear it so it doesn't hang around for a new workspace. The new workspace will mount its own value
  reset: () => void;
  // zustand merges by default so we can use a single updater
  update: (state: Partial<CombinedWorkspaceSettings>) => void;
};

export const WORKSPACE_SETTING_SOURCE: Record<
  keyof Omit<CombinedWorkspaceSettings, 'deprecatedMaxRuns'>,
  'panelBankSettings' | 'panelSettings'
> = {
  autoOrganizePrefix: 'panelBankSettings',
  pointVisualizationMethod: 'panelSettings',
  showEmptySections: 'panelBankSettings',
  sortAlphabetically: 'panelBankSettings',
  tooltipNumberOfRuns: 'panelSettings',
  displayFullRunName: 'panelSettings',
};

export const getWorkspaceSettings = create<
  CombinedWorkspaceSettings & WorkspaceSettingsUpdaters
>()(set => ({
  // feature defaults
  pointVisualizationMethod: DefaultWorkspaceSettings.pointVisualizationMethod,

  // handlers
  reset: () =>
    set(() => ({
      tooltipNumberOfRuns: DefaultWorkspaceSettings.tooltipNumberOfRuns,
      displayFullRunName: DefaultWorkspaceSettings.displayFullRunName,
    })),
  update: (newState: Partial<WorkspaceSettings>) => {
    set(() => newState);
  },

  // sections
  showEmptySections: DefaultWorkspaceSettings.showEmptySections,
  autoOrganizePrefix: DefaultWorkspaceSettings.autoOrganizePrefix,
  sortAlphabetically: DefaultWorkspaceSettings.sortAlphabetically,

  // tooltips
  displayFullRunName: DefaultWorkspaceSettings.displayFullRunName,

  // runs
  tooltipNumberOfRuns: DefaultWorkspaceSettings.tooltipNumberOfRuns,
}));

/**
 * These are the values that can be safely read from the zustand store without needing to be modified based on other app data. If any of these values do need to be modified by app state, they should move into custom hooks and be removed from this hook and EditableWorkspaceSettingsRead
 */
export const useWorkspaceSettings =
  (): Required<EditableWorkspaceSettingsRead> => {
    const settings = getWorkspaceSettings(s => s);

    return useMemo(
      () => ({
        autoOrganizePrefix: settings.autoOrganizePrefix,
        showEmptySections: settings.showEmptySections,
        sortAlphabetically: settings.sortAlphabetically,
        tooltipNumberOfRuns: settings.tooltipNumberOfRuns,
        displayFullRunName: settings.displayFullRunName,
      }),
      [settings]
    );
  };

import {assertUnreachable} from '@wandb/weave/common/util/types';

// eslint-disable-next-line import/no-cycle -- please fix if you can
import {ThunkResult} from '../../types/redux';
import * as ViewActionsInternal from '../views/actionsInternal';
import {deleteView} from '../views/api';
import {LoadableView, ViewType} from '../views/types';
// eslint-disable-next-line import/no-cycle
import {displayWorkspaceError, load, removeWorkspaceView} from './actions';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {
  copyToAndLoadPersonalWorkspace,
  copyToAndReloadSharedView,
  createNewViewWithSpecAndLoad,
  createNewWorkspaceAndLoad,
  EMPTY_WORKSPACE_DISPLAY_NAME,
  migrateAndLoadLegacyWorkspace,
} from './namedWorkspaceActionsInternal';
import {nwToast} from './namedWorkspaceToast';
import {determineLoadActionForUrl} from './namedWorkspaceUrls';
import {viewNameColumnForNamedWorkspace} from './names';
import {loadPerms} from './nwPermissions';
import * as Selectors from './selectors';
import {createSharedNamedWorkspaceId, NamedWorkspaceId} from './types';
import {handleNwHistoryNavigation, WorkspaceUrl} from './url';

export const PRIMARY_WORKSPACE_DISPLAY_NAME = 'Primary';

// The actions in this file are the actions to call when a user indicates they want
// to interact with workspaces. For example, they might be called as result of the user clicking a button

/*
 small helper function helpful during dev work - deletes a list of views. 
 it's easy to call from loadNamedWorkpace - you'll need to use this snippet:
 return async (dispatch, getState, client) => {
    deleteViews(client, view_id_list_here)
*/
// eslint-disable-next-line prefer-const
export let delView = null;
export const deleteViews = (client: any, viewServerIds: string[]) =>
  viewServerIds.forEach(id => deleteView(client, id, false));

/**
 * This soft deletes the user's draft of this workspace by changing
 * the view's name column.
 */
export const discardWorkspaceView = (
  workspaceStateId: string
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const workspace = state.workspaces[workspaceStateId];
    if (
      workspace == null ||
      workspace.viewRef == null ||
      workspace.nwId == null
    ) {
      throw new Error('invalid state - discardWorkspaceView');
    }

    await dispatch(removeWorkspaceView(workspaceStateId, workspace.viewRef.id));
    await dispatch(
      ViewActionsInternal.updateViewName(
        workspace.viewRef,
        viewNameColumnForNamedWorkspace(workspace.nwId, 'deleted')
      )
    );
  };
};

/**
 * Main entry point for switching views displayed in the user's workspace UI
 */
export const loadNamedWorkspace = (
  workspaceStateId: string,
  viewCid: string,
  nwId: NamedWorkspaceId | 'legacy'
): ThunkResult<void> => {
  return async (dispatch, getState, client) => {
    dispatch(ViewActionsInternal.deleteUndoRedoHistory());
    await dispatch(load(workspaceStateId, viewCid, nwId));
    handleNwHistoryNavigation('replace', nwId);
  };
};

/**
 * This determines which workspace user should land and updates redux.
 * It also replaces the url search param to make sure it matches with
 * the view that was loaded into redux.
 * Example: if a user visits a legacy workspace url and this function
 * determines that we need to migrateLegacy, we'll also want to update
 * url search from "?workspace=user-jofang" to "?nw=nwuserjofang".
 */
export const loadNamedWorkspaceForUrl = (
  workspaceStateId: string,
  workspaceUrl: WorkspaceUrl,
  legacyAndNwViews: LoadableView[],
  viewType: ViewType
): ThunkResult<Promise<string | void>> => {
  return async (dispatch, getState, client) => {
    const state = getState();
    const viewer = state.viewer.viewer;
    const workspace = state.workspaces[workspaceStateId];
    const relatedId = workspace?.relatedId;
    const nwPermInfo = await loadPerms(
      client,
      viewer,
      workspace.entityName,
      workspace.projectName
    );
    const action = determineLoadActionForUrl(
      workspaceUrl,
      viewer,
      legacyAndNwViews,
      relatedId,
      viewType,
      nwPermInfo
    );

    switch (action.type) {
      case 'load':
        dispatch(
          loadNamedWorkspace(workspaceStateId, action.viewCid, action.nwId)
        );
        handleNwHistoryNavigation('replace', action.nwId);
        break;
      case 'createAndLoadNewView':
        dispatch(
          createNewWorkspaceAndLoad(
            workspaceStateId,
            action.nwId,
            action.displayName
          )
        );
        handleNwHistoryNavigation('replace', action.nwId);
        break;
      case 'error':
        // In the future we will show the error in the main UI if appropriate
        dispatch(
          displayWorkspaceError(workspaceStateId, {
            errorCode: action.errorCode,
            userTitle: action.userTitle ?? 'Error loading workspace',
            userMessage:
              action.userMessage ?? 'This workspace could not be loaded.',
          })
        );
        break;
      case 'migrateLegacy':
        dispatch(
          migrateAndLoadLegacyWorkspace(
            workspaceStateId,
            action.legacyViewServerId,
            action.newNwId,
            action.newDisplayName
          )
        );
        handleNwHistoryNavigation('replace', action.newNwId);
        break;
      default:
        assertUnreachable(action);
    }
  };
};

/**
 * Main action to call when the user wants to create a new workspace
 */
export function createNewNamedWorkspace(
  workspaceStateId: string
): ThunkResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    const workspace = state.workspaces[workspaceStateId];

    const viewer = state.viewer.viewer;
    if (!viewer || !viewer.username) {
      throw new Error('must be logged in to create a named workspace');
    }

    const nwId = createSharedNamedWorkspaceId(workspace.relatedId);

    dispatch(
      createNewWorkspaceAndLoad(
        workspaceStateId,
        nwId,
        EMPTY_WORKSPACE_DISPLAY_NAME
      )
    );
  };
}

/**
 * Main action to call when the user wants copy a workspace's current state to their personal workspace
 */
export function copyToOwn(workspaceStateId: string): ThunkResult<void> {
  return (dispatch, getState) => {
    dispatch(copyToAndLoadPersonalWorkspace(workspaceStateId));
  };
}

/**
 * Main action to call when the user wants copy a workspace's current state to a new shared workspace.
 * This does not affect the workspace that is currently being viewed.
 */
export function saveAsNewWorkspace(
  workspaceStateId: string
): ThunkResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    const workspace = state.workspaces[workspaceStateId];
    const viewer = state.viewer.viewer;
    if (!viewer || !viewer.username) {
      throw new Error('must have viewer to create new workspace');
    }

    // Grab the  spec from redux
    const specSelResult = Selectors.makeWorkspaceSpecSelector(
      workspaceStateId,
      workspace.viewType
    )(state);
    const specResultData = Selectors.getDataChecked(specSelResult);
    if (specResultData.fullSpec == null) {
      throw new Error('saveAsNewWorkspace - error loading spec');
    }

    const nwId = createSharedNamedWorkspaceId(workspace.relatedId);
    await dispatch(
      createNewViewWithSpecAndLoad(
        workspaceStateId,
        nwId,
        specResultData.fullSpec,
        EMPTY_WORKSPACE_DISPLAY_NAME
      )
    );
  };
}

/**
 * Main action to call when the user wants to undo all changes since they first started in a session.
 * This is possible because we don't auto-save shared workspaces. We mark them as modified instead.
 */
export function undoUnsavedChanges(
  workspaceStateId: string
): ThunkResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const workspaceSelector =
      Selectors.makeWorkspaceReduxStateSelector(workspaceStateId)(state);
    const workspaceResult = Selectors.getDataChecked(workspaceSelector);

    const workspaceViewSelector =
      Selectors.makeWorkspaceViewSelector(workspaceStateId)(state);
    const workspaceViewResult = Selectors.getDataChecked(workspaceViewSelector);

    if (workspaceResult?.nwId == null) {
      throw new Error('Error undoing unsaved changes');
    }

    dispatch(
      load(workspaceStateId, workspaceViewResult.view.cid, workspaceResult.nwId)
    );
  };
}

/**
 * Main action to call when the user wants to reset to default view. This is like a factory reset.
 * This should be an undoable action.
 */
export function resetToDefaultSpec(
  workspaceStateId: string
): ThunkResult<void> {
  return async (dispatch, getState) => {
    const state = getState();
    const workspaceSelector =
      Selectors.makeWorkspaceReduxStateSelector(workspaceStateId)(state);
    const workspaceResult = Selectors.getDataChecked(workspaceSelector);

    const workspaceViewSelector =
      Selectors.makeWorkspaceViewSelector(workspaceStateId)(state);
    const workspaceViewResult = Selectors.getDataChecked(workspaceViewSelector);

    const workspaceSpecSelector = Selectors.makeWorkspaceSpecSelector(
      workspaceStateId,
      workspaceResult.viewType
    )(state);
    const workspaceSpecResult = Selectors.getDataChecked(workspaceSpecSelector);
    if (workspaceResult?.nwId == null || workspaceResult.defaultSpec == null) {
      throw new Error('Error reseting view to default spec');
    }

    await dispatch(
      ViewActionsInternal.undoableUpdateViewSpec(
        {id: workspaceViewResult.view.cid},
        {
          prevSpec: workspaceSpecResult.fullSpec,
          newSpec: workspaceResult.defaultSpec,
          wholeIsDefinitelyNew: false,
        }
      )
    );
  };
}

/**
 * Main action to call when the user wants to delete their shared workspace.
 * Users cannot delete their personal workspaces at the moment.
 */
export const deleteWorkspaceViewAndLoad = (
  workspaceStateId: string,
  loadingInfo: {
    viewCid?: string;
    nwId?: NamedWorkspaceId;
  }
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const workspace = state.workspaces[workspaceStateId];
    if (
      workspace == null ||
      workspace.viewRef == null ||
      workspace.nwId == null
    ) {
      nwToast('Error deleting workspace.');
      throw new Error('invalid state - discardWorkspaceView');
    }

    await dispatch(removeWorkspaceView(workspaceStateId, workspace.viewRef.id));
    await dispatch(
      ViewActionsInternal.updateViewName(
        workspace.viewRef,
        viewNameColumnForNamedWorkspace(workspace.nwId, 'deleted')
      )
    );

    const view = state.views.views[workspace.viewRef?.id];
    const displayName =
      view.displayName.length === 0 ? 'workspace' : `"${view.displayName}"`;
    nwToast(`Successfully deleted ${displayName}`);

    if (loadingInfo?.viewCid && loadingInfo?.nwId) {
      await dispatch(
        loadNamedWorkspace(
          workspaceStateId,
          loadingInfo?.viewCid,
          loadingInfo?.nwId
        )
      );
      // Do a replace instead of a push because we don't want to hit a deleted view
      // when user uses the back/forward button
      handleNwHistoryNavigation('replace', loadingInfo?.nwId);
    }
  };
};

/**
 * Main action to call when the user want to save their changes because we turn off auto-saving for shared workspaces.
 */
export const saveWorkspaceChanges = (
  workspaceStateId: string
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    dispatch(copyToAndReloadSharedView(workspaceStateId));
  };
};

import {Select} from '@wandb/weave/components/Form/Select';
import React from 'react';

export enum ShowMinMaxOnHover {
  ALWAYS = 'always',
  NEVER = 'never',
  ON_HOVER = 'onHover',
}

export const DEFAULT_MIN_MAX_ON_HOVER = ShowMinMaxOnHover.NEVER;

export const showMinMaxOnHoverOptions: Record<
  ShowMinMaxOnHover,
  {label: string; value: ShowMinMaxOnHover}
> = {
  [ShowMinMaxOnHover.NEVER]: {label: 'Never', value: ShowMinMaxOnHover.NEVER},
  [ShowMinMaxOnHover.ON_HOVER]: {
    label: 'On hover',
    value: ShowMinMaxOnHover.ON_HOVER,
  },
  [ShowMinMaxOnHover.ALWAYS]: {
    label: 'Always',
    value: ShowMinMaxOnHover.ALWAYS,
  },
};

const minMaxValueMapper: Record<string, ShowMinMaxOnHover> = {
  [ShowMinMaxOnHover.NEVER]: ShowMinMaxOnHover.NEVER,
  [ShowMinMaxOnHover.ALWAYS]: ShowMinMaxOnHover.ALWAYS,
  [ShowMinMaxOnHover.ON_HOVER]: ShowMinMaxOnHover.ON_HOVER,
  true: ShowMinMaxOnHover.ON_HOVER,
  false: ShowMinMaxOnHover.ALWAYS,
};

export function deriveSafeValueMinMaxOnHover(
  value: boolean | undefined | ShowMinMaxOnHover
): ShowMinMaxOnHover {
  if (value == null) {
    return ShowMinMaxOnHover.NEVER;
  }

  return minMaxValueMapper[value.toString()] ?? DEFAULT_MIN_MAX_ON_HOVER;
}

export const ShowMinMaxOnHoverSelect = ({
  onChange,
  value,
}: {
  onChange: (
    value:
      | (typeof showMinMaxOnHoverOptions)[keyof typeof showMinMaxOnHoverOptions]
      | null
  ) => void;
  value: ShowMinMaxOnHover;
}) => {
  return (
    <>
      <label
        className="mr-12 text-sm text-moon-500"
        htmlFor="min-max-on-hover-switch">
        Show min/max values as a shaded area
      </label>
      <Select<
        (typeof showMinMaxOnHoverOptions)[keyof typeof showMinMaxOnHoverOptions]
      >
        className="min-w-[120px]"
        options={Object.values(showMinMaxOnHoverOptions)}
        onChange={onChange}
        // this is a string version of the value options
        value={showMinMaxOnHoverOptions[value]}
      />
    </>
  );
};

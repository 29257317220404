import {useParams} from 'react-router';

// eslint-disable-next-line import/no-cycle -- please fix if you can
import {getWorkspaceSettings} from '../../features/workspaceSettings/store';
import {useIsInLoadedReportContext} from '../../pages/ReportPage/LoadedReportContext';
import {
  useRampFlagDefaultGorillaOutliers,
  useRampFlagGorillaOutliersAvailable,
} from '../../util/rampFeatureFlags';
import {
  POINT_VISUALIZATION_OPTIONS,
  PointVisualizationOptions,
} from '../WorkspaceSettingsModal/controls/PointVisualizationTypes';

export const pointVisualizationIsBucketing = (p: PointVisualizationOptions) => {
  return (
    p === POINT_VISUALIZATION_OPTIONS.BucketingGorilla ||
    p === POINT_VISUALIZATION_OPTIONS.ForceBucketing
  );
};

export const usePointVisualizationMethod = (): PointVisualizationOptions => {
  const {pointVisualizationMethod} = getWorkspaceSettings();

  const hasRunOutliersAccess = useRampFlagGorillaOutliersAvailable();

  const {entityName} = useParams<{entityName?: string}>();
  if (!entityName) {
    // This check should narrow the type of `entityName` from `undefined | string`
    // to `string` in subsequent code.
    throw new RangeError(`Current entity name unexpectedly empty!`);
  }
  const isFullFidelityDefaultEligible =
    useRampFlagDefaultGorillaOutliers(entityName);

  const isInReport = useIsInLoadedReportContext();

  // users w/out access to run outliers will always be in sampling mode
  if (!hasRunOutliersAccess) {
    return POINT_VISUALIZATION_OPTIONS.Sampling;
  }

  // bucketing is not available in reports
  if (isInReport) {
    return POINT_VISUALIZATION_OPTIONS.Sampling;
  }

  /**
   * users who are enrolled to default to bucketing mode will be defaulted IF
   * they have yet to select a preferred point visualization method for the workspace
   */
  if (
    isFullFidelityDefaultEligible &&
    pointVisualizationMethod === POINT_VISUALIZATION_OPTIONS.SamplingByDefault
  ) {
    return POINT_VISUALIZATION_OPTIONS.ForceBucketing;
  }

  // Users who haven't specificed a preference for the workspace will be defaulted to sampling
  if (
    pointVisualizationMethod === POINT_VISUALIZATION_OPTIONS.SamplingByDefault
  ) {
    return POINT_VISUALIZATION_OPTIONS.Sampling;
  }

  if (pointVisualizationMethod) {
    return pointVisualizationMethod;
  }

  return POINT_VISUALIZATION_OPTIONS.Sampling;
};

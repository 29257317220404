import {isEqual} from 'lodash';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';

import {RootState} from '../../../types/redux';
import {usePropsSelector} from '../../hooks';
import {Ref as SectionRef} from '../panelBankSectionConfig/types';
import {makeOptionalPartSelector} from '../selectors';

export const useSectionSettings = (sectionRef: SectionRef) => {
  const sectionSettings = useSelector(
    makeSectionSettingsSelector(sectionRef),
    isEqual
    // 'makeWorkspaceSettingsSelector' // comment back in for debugging
  );
  return sectionSettings;
};

export const makeSectionSettingsSelector = (sectionRef: SectionRef) => {
  return (state: RootState) => {
    return state.views.parts['panel-bank-section-config'][sectionRef.id]
      .sectionSettings;
  };
};

export const useSectionPanels = (sectionRef?: SectionRef) => {
  return usePropsSelector(makeSectionPanelsSelector, sectionRef);
};

export const makeSectionPanelsSelector = (sectionRef?: SectionRef) => {
  return createSelector(
    makeOptionalPartSelector(sectionRef),
    (state: RootState) => state.views.parts.panel,
    (section, panelParts) =>
      section?.panelRefs.map(panelRef => panelParts[panelRef.id])
  );
};

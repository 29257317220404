import {getType} from 'typesafe-actions';

import {setInShallowClone} from '../../util/utility';
import * as GroupSelectionsActions from './groupSelections/actions';
import * as GroupSelectionsUtils from './groupSelections/utils';
import * as PanelActions from './panel/actions';
import * as PanelBankConfigActions from './panelBankConfig/actions';
import * as PanelBankConfigActionsInternal from './panelBankConfig/actionsInternal';
import * as PanelBankConfigUtils from './panelBankConfig/utils';
import * as PanelBankSectionConfigActions from './panelBankSectionConfig/actions';
import * as PanelBankSectionConfigUtils from './panelBankSectionConfig/utils';
import {ActionType, ViewReducerState} from './reducerSupport';
import * as WorkspaceSettingsActions from './workspaceSettings/actions';
import * as WorkspaceSettingsUtils from './workspaceSettings/utils';

export const applyAndMakeInverseActionImmutable = (
  state: ViewReducerState,
  action: ActionType
): [ViewReducerState, ActionType] => {
  switch (action.type) {
    case getType(GroupSelectionsActions.toggleSelection): {
      const {ref, run, depth} = action.payload;
      return GroupSelectionsUtils.toggleSelection(state, ref, run, depth);
    }

    case getType(PanelActions.setConfig): {
      const {ref, config, key} = action.payload;
      const prevPanel = state.parts[ref.type][ref.id];
      const newPanel = Object.assign({}, prevPanel, {config, key});
      const newState = setInShallowClone(
        state,
        ['parts', ref.type, ref.id],
        newPanel
      );
      const inverseAction = PanelActions.setConfig(
        action.payload.ref,
        prevPanel.config,
        prevPanel.key
      );
      return [newState, inverseAction];
    }

    case getType(PanelActions.updateConfig): {
      const {ref, configUpdate} = action.payload;
      const prevPanel = state.parts[ref.type][ref.id];
      const newPanelConfig = Object.assign({}, prevPanel.config, configUpdate);
      const newPanel = Object.assign({}, prevPanel, {
        config: newPanelConfig,
        key: undefined,
      });
      const newState = setInShallowClone(
        state,
        ['parts', ref.type, ref.id],
        newPanel
      );
      const inverseAction = PanelActions.setConfig(
        action.payload.ref,
        prevPanel.config,
        prevPanel.key
      );
      return [newState, inverseAction];
    }

    case getType(PanelBankConfigActions.addPanelsBySpec): {
      const {ref, specs} = action.payload;
      return PanelBankConfigUtils.addPanelsBySpec(state, ref, specs);
    }

    case getType(PanelBankConfigActions.clearAllPanels): {
      const {ref, workspaceSettingsRef} = action.payload;
      return PanelBankConfigUtils.clearAllPanels(
        state,
        ref,
        workspaceSettingsRef
      );
    }

    case getType(PanelBankConfigActions.clearAllPanelsUndo): {
      const {ref, workspaceSettingsRef, sectionRefs, shouldAutoGeneratePanels} =
        action.payload;
      return PanelBankConfigUtils.clearAllPanelsUndo(
        state,
        ref,
        workspaceSettingsRef,
        sectionRefs,
        shouldAutoGeneratePanels
      );
    }

    case getType(PanelBankConfigActions.openOrCloseAllSections): {
      const {ref, isOpen} = action.payload;
      return PanelBankConfigUtils.updateSections(
        state,
        ref,
        curSectionConfig => {
          if (curSectionConfig.isOpen === isOpen) {
            return curSectionConfig;
          }
          return Object.assign({}, curSectionConfig, {isOpen});
        }
      );
    }

    case getType(PanelBankConfigActions.updateAllLinePlotSectionSettings): {
      const {ref, settings} = action.payload;
      return PanelBankConfigUtils.updateAllLinePlotSectionSettings(
        state,
        ref,
        settings
      );
    }

    case getType(PanelBankConfigActionsInternal.setAllSections): {
      const {ref, newSectionsNormalized} = action.payload;
      return PanelBankConfigUtils.updateSections(
        state,
        ref,
        (curSectionConfig, i) => newSectionsNormalized[i]
      );
    }

    case getType(PanelBankSectionConfigActions.toggleIsOpen): {
      const {ref} = action.payload;
      return PanelBankSectionConfigUtils.toggleIsOpen(state, ref);
    }

    case getType(PanelBankSectionConfigActions.updateLinePlotSectionSettings): {
      const {ref, settings} = action.payload;
      return PanelBankSectionConfigUtils.updateLinePlotSectionSettings(
        state,
        ref,
        settings
      );
    }

    case getType(WorkspaceSettingsActions.disableAutoGeneratePanels): {
      const {ref, panelBankConfigRef, expectedPanels} = action.payload;
      return WorkspaceSettingsUtils.disableAutoGeneratePanels(
        state,
        ref,
        panelBankConfigRef,
        expectedPanels
      );
    }

    case getType(WorkspaceSettingsActions.enableAutoGeneratePanels): {
      const {ref, panelBankConfigRef, expectedPanels} = action.payload;
      return WorkspaceSettingsUtils.enableAutoGeneratePanels(
        state,
        ref,
        panelBankConfigRef,
        expectedPanels
      );
    }

    case getType(WorkspaceSettingsActions.updateLinePlotWorkspaceSettings): {
      const {ref, settings} = action.payload;
      return WorkspaceSettingsUtils.updateLinePlotWorkspaceSettings(
        state,
        ref,
        settings
      );
    }

    case getType(PanelBankConfigActions.updateSettings): {
      const {ref, panelBankSettings} = action.payload;
      return PanelBankConfigUtils.updateSettings(state, ref, panelBankSettings);
    }
  }
  throw new Error('Action not undoable');
};

export const applyUndoableActionImmutable = (
  state: ViewReducerState,
  action: ActionType
) => {
  const [newState, inverseAction] = applyAndMakeInverseActionImmutable(
    state,
    action
  );
  return Object.assign(newState, {
    undoActions: state.undoActions.concat(inverseAction),
    redoActions: [],
  });
};

import {Tooltip} from '@material-ui/core';
import React from 'react';
import {twMerge} from 'tailwind-merge';

import {
  AccountIcon,
  AccountIconLocation,
} from '../pages/Billing/AccountSettings/AccountIcon';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {MatchingTeamTypeWithState} from './annotateTeamsWithState';
import * as S from './JoinMatchingOrgTeams.styles';
import {AccountType} from './Search/SearchNav/types';
import {MatchingTeamType} from './useMatchedTeams';
import * as UsageStyles from './WBUsageTypeSelection.styles';

type Props = MatchingTeamTypeWithState & {
  handleToggleTeam: (team: MatchingTeamType) => void;
  teamsWithErrors: Map<string, string>;
  className?: string;
};

export const MatchingTeam = ({
  team,
  isSelected,
  isDisabled,
  isAlreadyMember,
  handleToggleTeam,
  teamsWithErrors,
  className,
}: Props) => {
  return (
    <Tooltip
      title={isAlreadyMember ? 'You are already a member of this team' : ''}
      key={team.id}>
      <UsageStyles.SelectionItemContainer
        className={twMerge('mb-10 last:mb-0', className)}
        data-test={`matching-team-${team.name}`}
        $selected={isSelected}
        onClick={() => {
          if (!isDisabled) {
            handleToggleTeam(team);
          }
        }}>
        <UsageStyles.IconAndTextContainer>
          <AccountIcon
            accountType={AccountType.Corporate}
            location={AccountIconLocation.Onboarding}
            isSelected={isSelected}></AccountIcon>
          <UsageStyles.ItemLabelAndDescriptionContainer>
            <UsageStyles.ItemLabel>{team.name}</UsageStyles.ItemLabel>
            {team.memberCount > 0 && (
              <UsageStyles.ItemDescription>
                {team.memberCount} member
                {team.memberCount > 1 ? 's' : ''}
              </UsageStyles.ItemDescription>
            )}
            {teamsWithErrors.has(team.id) && (
              <S.ErrorMsg>{teamsWithErrors.get(team.id)}</S.ErrorMsg>
            )}
          </UsageStyles.ItemLabelAndDescriptionContainer>
        </UsageStyles.IconAndTextContainer>
        <UsageStyles.Checkmark selected={isSelected} />
      </UsageStyles.SelectionItemContainer>
    </Tooltip>
  );
};

import type {PanelLayout, PanelWithConfig} from '../../../util/panelTypes';
import {SmoothingTypeValues} from '../../elements/SmoothingConfig';
import {PointVisualizationOptions} from '../../WorkspaceSettingsModal/controls/PointVisualizationTypes';
import {ShowMinMaxOnHover} from './ShowMinMaxOnHover';

/**
 * PanelSettings are settings that apply to a _panel_; not necessarily any
 * one specific panel, but panels in general. They may be used to define
 * common settings for a group of panels, such as all panels in a workspace
 * or section of a workspace.
 */
export interface Settings {
  ignoreOutliers?: boolean;
  localSmoothingSettingsActive?: number;
  localxAxisSettingsActive?: number;
  smoothingActive?: boolean;
  smoothingType: SmoothingTypeValues;
  smoothingWeight: number;
  useRunsTableGroupingInPanels: boolean;
  xAxis: XAxisValues | string;
  xAxisActive?: boolean;
  xAxisMax?: number;
  xAxisMin?: number;

  /** Whether run names should be colored in panel tooltips */
  colorRunNames?: boolean;
  /** "no crop" option for chart legends on the primary chart to accommodate long run names with crucial information in the middle */
  displayFullRunName?: boolean;
  /**
   * Workspace setting level uses `maxRuns`, but panel config uses `limit`.
   * This setting tells us maximum number of runs to include for a panel
   */
  maxRuns?: number;
  /** Default point vizualization method for runs line plots */
  pointVisualizationMethod?: PointVisualizationOptions;
  /** Whether the min/max lines should be displayed on a bucketed chart */
  showMinMaxOnHover?: ShowMinMaxOnHover | boolean;

  /**
   * workspace setting level uses `supressLegends` but panel configs use `showLegend`.
   * We want resolve to the panel config when we feed in setting information.
   * This setting tells us whether legends should be hidden in runs line plots
   */
  suppressLegends?: boolean;

  /** Determines how many runs are displayed in panel tooltips */
  tooltipNumberOfRuns?: TooltipNumberOfRunsOptions;
}

export interface SmoothingSettings {
  smoothingWeight: number;
  smoothingType: SmoothingTypeValues;
}

export enum TooltipNumberOfRunsOptions {
  Single = 'single',
  Default = 'default',
  All = 'all_runs',
}

/**
 * Indicates which menu was selected in the Settings UX
 */
export type SelectedSettingType = 'line-plot' | 'workspace-layout';

export type YAxisType = 'linear' | 'log';
export type XAxisType = 'linear' | 'log' | 'time';

export enum XAxisValues {
  Step = '_step',
  RunTime = '_runtime',
  Timestamp = '_timestamp',
  AbsoluteRunTime = '_absolute_runtime',
}

export type XAxisSettingType = {
  xAxis?: XAxisValues | string;
  xAxisMin?: number;
  xAxisMax?: number;
};

/**
 * These are either the workspace or section settings.
 * They need to be further resolved at the individual panel level.
 *
 * We are slowly migrating settings to a more consolidated structure.
 * This type helps with this process.
 */
export type InheritedSettings = XAxisSettingType & {
  ignoreOutliers?: boolean;

  // smoothing
  smoothingWeight?: number;
  smoothingType?: SmoothingTypeValues;

  /**
   * workspace setting level uses `supressLegends` but panel configs use `showLegend`.
   * We want resolve to the panel config when we feed in setting information.
   * This setting tells us whether legends should be hidden in runs line plots
   */
  showLegend?: boolean;
  suppressLegends?: boolean;

  /**
   * Workspace setting level uses `maxRuns`, but panel config uses `limit`.
   * This setting tells us maximum number of runs to include for a panel
   */
  maxRuns?: number;
  limit?: number;

  /** respect the runs table grouping in the chart */
  useRunsTableGroupingInPanels?: boolean;
  /** Whether run names should be colored in panel tooltips */
  colorRunNames?: boolean;
  /** if true, only show the highlighted run in companion plots */
  highlightedCompanionRunOnly?: boolean;
  /** Whether the min/max lines should be displayed on a bucketed chart */
  showMinMaxOnHover?: ShowMinMaxOnHover | boolean;
};

export type LinePlotSettings = InheritedSettings & {
  /** "no crop" option for chart legends on the primary chart to accommodate long run names with crucial information in the middle */
  displayFullRunName?: boolean;
  /** Default point vizualization method for runs line plots */
  pointVisualizationMethod?: PointVisualizationOptions;
  /** Determines how many runs are displayed in panel tooltips */
  tooltipNumberOfRuns?: TooltipNumberOfRunsOptions;
};

export type SettingSelectOptionType = {
  value: string;
  label: string;
};

export type LinePlotPanel = PanelWithConfig<'Run History Line Plot'> & {
  key?: string | undefined;
} & PanelLayout;

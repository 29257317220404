import _ from 'lodash';

import {SectionPanelSorting} from '../../../components/PanelBank/types';
import {LinePlotSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {panelSortingKeyFromPanel} from '../../../util/panelbank';
import {getNewGridItemLayout} from '../../../util/panelbankGrid';
import {LayedOutPanel} from '../../../util/panelTypes';
import {setInShallowClone} from '../../../util/utility';
import * as Normalize from '../normalize';
import {StateType} from '../normalizerSupport';
import * as PanelTypes from '../panel/types';
import {ActionType, ViewReducerState} from '../reducerSupport';
import * as Actions from './actions';
import {PanelBankSectionConfigNormalized, Ref} from './types';

export const toggleIsOpen = (
  state: ViewReducerState,
  ref: Ref
): [ViewReducerState, ActionType] => {
  const curSectionConfig = state.parts[ref.type][ref.id];
  const newState = setInShallowClone(
    state,
    ['parts', ref.type, ref.id, 'isOpen'],
    !curSectionConfig.isOpen
  );
  const inverseAction = Actions.toggleIsOpen(ref);
  return [newState, inverseAction];
};

export function addPanels(
  state: ViewReducerState,
  panels: LayedOutPanel[],
  ref: Ref,
  fatPanel?: boolean,
  callbackFn?: (panel: LayedOutPanel, newPanelRef: PanelTypes.Ref) => void
) {
  const newPanelRefs = panels.map(panel => {
    // Add layout for grid sections
    const panelWithLayout = {
      ...panel,
      layout: getNewGridItemLayout(
        Normalize.denormalize(state.parts, ref)
          .panels.map(p => p.layout)
          .filter(l => l),
        fatPanel
      ),
    };
    const newPanelRef = Normalize.addObj(
      state.parts,
      'panel',
      ref.viewID,
      panelWithLayout
    );
    callbackFn?.(panel, newPanelRef);
    return newPanelRef;
  });

  const normalizedSectionConfig: PanelBankSectionConfigNormalized =
    state.parts[ref.type][ref.id];

  if (normalizedSectionConfig.sorted === SectionPanelSorting.Alphabetical) {
    insertPanelsAlphabetically(
      state.parts,
      normalizedSectionConfig,
      newPanelRefs
    );
  } else {
    // Inserts at the beginning of section.panels
    normalizedSectionConfig.panelRefs = newPanelRefs.concat(
      normalizedSectionConfig.panelRefs
    );
  }

  // If section is closed, open it
  if (!normalizedSectionConfig.isOpen) {
    normalizedSectionConfig.isOpen = true;
  }

  return newPanelRefs;
}

export function addPanel(
  state: ViewReducerState,
  panel: LayedOutPanel,
  ref: Ref,
  fatPanel?: boolean,
  callbackFn?: (panel: LayedOutPanel, newPanelRef: PanelTypes.Ref) => void
) {
  const newPanelRefs = addPanels(state, [panel], ref, fatPanel, callbackFn);
  return Actions.deletePanel(ref, newPanelRefs[0]);
}

const movePanelAlphabeticallyKey = (key: string | undefined) => {
  if (key === undefined) {
    return '0';
  } else {
    return '1' + key;
  }
};

export function immutableInsertPanelsAlphabetically(
  parts: StateType,
  normalizedSectionConfig: PanelBankSectionConfigNormalized,
  newPanelRefs: PanelTypes.Ref[]
): PanelBankSectionConfigNormalized {
  return {
    ...normalizedSectionConfig,
    panelRefs: normalizedSectionConfig.panelRefs
      .concat(newPanelRefs)
      .sort((refA, refB) => {
        const keyA = panelSortingKeyFromPanel(parts[refA.type][refA.id]);
        const keyB = panelSortingKeyFromPanel(parts[refB.type][refB.id]);
        return movePanelAlphabeticallyKey(keyA).localeCompare(
          movePanelAlphabeticallyKey(keyB)
        );
      }),
  };
}

export function insertPanelsAlphabetically(
  parts: StateType,
  normalizedSectionConfig: PanelBankSectionConfigNormalized,
  newPanelRefs: PanelTypes.Ref[]
) {
  normalizedSectionConfig.panelRefs = normalizedSectionConfig.panelRefs
    .concat(newPanelRefs)
    .sort((refA, refB) => {
      const keyA = panelSortingKeyFromPanel(parts[refA.type][refA.id]);
      const keyB = panelSortingKeyFromPanel(parts[refB.type][refB.id]);
      return movePanelAlphabeticallyKey(keyA).localeCompare(
        movePanelAlphabeticallyKey(keyB)
      );
    });
}

export function movePanelAlphabeticallyInSection(
  state: ViewReducerState,
  normalizedSectionConfig: PanelBankSectionConfigNormalized,
  panelRef: PanelTypes.Ref,
  panel: LayedOutPanel
) {
  const panelKeys = normalizedSectionConfig.panelRefs
    .filter(oldPanelRef => oldPanelRef.id !== panelRef.id)
    .map(oldPanelRef => {
      const sectionPanel = Normalize.denormalize(state.parts, oldPanelRef);
      return panelSortingKeyFromPanel(sectionPanel);
    });
  const newPanelIndex = _.sortedIndexBy(
    panelKeys,
    panelSortingKeyFromPanel(panel),
    movePanelAlphabeticallyKey
  );
  const origPanelIndex = normalizedSectionConfig.panelRefs.findIndex(
    sectionPanelRef => sectionPanelRef.id === panelRef.id
  );
  normalizedSectionConfig.panelRefs.splice(origPanelIndex, 1);
  normalizedSectionConfig.panelRefs.splice(newPanelIndex, 0, panelRef);
}

export const updateLinePlotSectionSettings = (
  state: ViewReducerState,
  ref: Ref,
  settings: Partial<LinePlotSettings> | undefined
): [ViewReducerState, ActionType] => {
  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [ref.type]: {
        ...state.parts[ref.type],
      },
    },
  };

  const prevSectionSettings =
    newState.parts['panel-bank-section-config'][ref.id].sectionSettings;

  newState.parts['panel-bank-section-config'][ref.id] = {
    ...newState.parts['panel-bank-section-config'][ref.id],
    sectionSettings: {
      ...prevSectionSettings,
      linePlot: {
        ...(prevSectionSettings?.linePlot ?? {}),
        ...settings,
      },
    },
  };

  const inverseAction = Actions.updateLinePlotSectionSettings(
    ref,
    prevSectionSettings?.linePlot
  );
  return [newState, inverseAction];
};

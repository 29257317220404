import moment from 'moment';
import * as React from 'react';

import {TruncationType} from '../components/common/TruncateText/TruncateTextTooltip';
import {RunWithRunsetInfo} from '../containers/RunsDataLoader';
import {FancyLegendProps, LegendData} from './plotHelpers/legendTypes';
import {isTimeKey} from './plotHelpers/time';
import {PlotType} from './plotHelpers/types';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {displayValue, truncateString} from './runhelpers';
import * as Run from './runs';
import * as RunTypes from './runTypes';

const ALL_CROSSHAIR_VALUES_REGEX = /(\[\[[^\]]+\]\])/g;
const INSIDE_CROSSHAIR_VALUES_REGEX = /\[\[([^\]]+)\]\]/;

function valueForTemplateKey(
  keyStr: string,
  run: RunWithRunsetInfo,
  groupKeys: RunTypes.Key[],
  metricName: string
): string | React.ReactElement {
  if (keyStr === 'metricName') {
    return metricName;
  }
  if (keyStr === 'runsetName') {
    return run.runsetInfo?.name ?? '';
  }

  // old legends used name to mean run:displayName
  if (keyStr === 'run:displayName' || keyStr === 'name') {
    if (groupKeys.length === 0) {
      const value = Run.getValueFromKeyString(run, 'run:displayName');
      return value?.toString() ?? '';
    }

    if (Run.keysEqual(groupKeys[0], RunTypes.GROUP_BY_ALL_KEY)) {
      // grouped all from runs table
      return 'Group';
    }
    if (groupKeys[0].name === 'None') {
      // grouped all from panel
      return 'Group';
    }

    return groupKeys
      .map(
        k =>
          `${Run.rawConfigKeyDisplayName(k.name)}: ${displayValue(
            Run.getValue(run, k)
          )}`
      )
      .join(', ');
  }

  const key = Run.keyFromString(keyStr);
  if (key == null) {
    return keyStr;
  }

  // We need to add .value back to the key
  // But if a keyStr looks like a.b.c, there's some ambiguity
  // Either the user named it with dots, or it's a nested key that we flattened and joined with dots
  // So here we test all possibilities against the run data
  const possibleKeys = Run.keyPossibilities(key);
  for (const possibleKey of possibleKeys) {
    const possibleKeyStr = Run.keyToString(possibleKey);
    const value = Run.getValueFromKeyString(run, possibleKeyStr);
    if (value != null) {
      return isTimeKey(possibleKeyStr)
        ? moment(value.toString()).format('MMM DD h:mma')
        : displayValue(value);
    }
  }

  return '';
}

function formatXAxisValue(xAxis: string, val: string | number | Date) {
  if (xAxis === 'Wall Time') {
    if (typeof val === 'string') {
      val = Number.parseFloat(val);
    }
    return moment(val).format('MMM DD h:mma');
  }

  return displayValue(val);
}

export const getDefaultLegendTemplate = ({
  aggregateMetrics,
  isGrouped,
  isFullFidelity,
  isSmoothed,
  legendFields,
  metrics,
  singleRun,
  type,
  useRunsets,
}: {
  aggregateMetrics: boolean;
  isGrouped: boolean;
  isFullFidelity: boolean;
  isSmoothed: boolean;
  legendFields: string[];
  metrics: string[];
  singleRun: boolean;
  type: PlotType;
  useRunsets: boolean;
}) => {
  const defaultLegendSpec = singleRun ? [] : ['run:displayName'];
  let legendSpec;
  if (legendFields.length === 0) {
    // some older runs have legendfields empty and in the case
    // of a multirun plot they expect the legend to show displayName
    legendSpec = [...defaultLegendSpec];
  } else {
    legendSpec = [...legendFields];
  }

  if (useRunsets) {
    // Only show runset name when there are multiple runsets.
    legendSpec.push('runsetName');
  }
  if (!aggregateMetrics && (metrics.length > 1 || singleRun)) {
    // If there are multiple metrics or it's a run plot,
    // include the metric name in the legend
    legendSpec.push('metricName');
  }

  const defaultLegendTemplate = legendTemplateFromFields({
    legendFields: legendSpec,
    isGrouped,
    isFullFidelity,
    isSmoothed,
    type,
  });

  return defaultLegendTemplate;
};

export function parseLegendTemplate(
  legendTemplate: string,
  keepCrosshairValueTemplates: boolean,
  run: RunWithRunsetInfo,
  groupKeys: RunTypes.Key[],
  metricName: string
): string {
  /**
   * Goal of this function is to take a legendTemplate like:
   * ${run:displayName} ${run:createdAt} [[ ${x}: ${y} ]]
   * and replace the ${run:displayName} ${run:createdAt}
   * with appropriate values while not touching stuff inside [[ ]]
   */
  let parsedLegend = legendTemplate;
  const squareBracketMatches = legendTemplate.split(/(\[\[[^\]]+\]\])/g);

  squareBracketMatches.forEach(squareBracketMatch => {
    if (squareBracketMatch.match(/\[\[([^\]]+)\]\]/)) {
      // If keepCrosshairValueTemplates don't do anything inside double square brackets
      // otherwise remove it.
      if (!keepCrosshairValueTemplates) {
        parsedLegend = parsedLegend.replace(squareBracketMatch, '');
      }
    } else {
      const matches = squareBracketMatch.match(/\${([^}])+}/g);
      if (matches) {
        matches.forEach(match => {
          const keyStr = match.replace(/^\${\s*/, '').replace(/\s*}$/, '');
          const value = valueForTemplateKey(keyStr, run, groupKeys, metricName);
          parsedLegend = parsedLegend.replace(match, value.toString());
        });
      }
    }
  });
  return parsedLegend.trim();
}

export function legendTemplateFieldNames(legendTemplate: string) {
  // extracts an array of field names used in the legend
  const fieldNames: string[] = [];
  const matches = legendTemplate.match(/\${([^}])+}/g);
  if (matches) {
    matches.forEach(match => {
      const fieldName = match.replace(/^\${\s*/, '').replace(/\s*}$/, '');
      fieldNames.push(fieldName);
    });
  }
  return fieldNames;
}

export function legendTemplateRemoveCrosshairValues(legendTemplate: string) {
  // removes the stuff inside [[ ]]
  let newLegendTemplate = legendTemplate;
  const squareBracketMatches = legendTemplate.split(ALL_CROSSHAIR_VALUES_REGEX);
  squareBracketMatches.forEach(squareBracketMatch => {
    if (squareBracketMatch.match(INSIDE_CROSSHAIR_VALUES_REGEX)) {
      newLegendTemplate = newLegendTemplate.replace(squareBracketMatch, '');
    }
  });
  return newLegendTemplate.trim();
}

const fontStyle = {fontFamily: 'Source Sans Pro'};
export function legendTemplateInsertCrosshairValues({
  addDefaultTemplateIfCrosshairValuesMissing,
  key,
  legendTemplate,
  maxLength = 10,
  simpleVersion = false,
  truncateOutsideOfCrosshairValues = false,
  type,
  values,
  runNameTruncationType,
}: {
  addDefaultTemplateIfCrosshairValuesMissing: boolean;
  key: string;
  legendTemplate: string;
  maxLength?: number;
  simpleVersion?: boolean;
  truncateOutsideOfCrosshairValues?: boolean;
  type: PlotType;
  values: LegendData;
  runNameTruncationType?: TruncationType;
}) {
  /**
   * Designed for inserting values into legend
   * Should be called after parseLegend
   * matches inside of [[ ]]
   *
   * truncateOutsideOfCrosshairValues is designed for a legend outside of where
   * the cursor is hovering, so it truncates sections outside of square brackets.
   */

  let fixedLegendTemplate = legendTemplate;
  if (addDefaultTemplateIfCrosshairValuesMissing) {
    if (legendTemplate.match(ALL_CROSSHAIR_VALUES_REGEX) == null) {
      fixedLegendTemplate =
        legendTemplate +
        defaultCrosshairValues({
          isGrouped: false,
          isSmoothed: false,
          isFullFidelity: false,
          type,
        });
    }
  }
  const squareBracketMatches = fixedLegendTemplate.split(
    ALL_CROSSHAIR_VALUES_REGEX
  );
  return (
    <span key={'crosshair-val' + key + legendTemplate}>
      {squareBracketMatches.map((squareBracketMatch, i) => {
        if (squareBracketMatch.match(INSIDE_CROSSHAIR_VALUES_REGEX)) {
          const squareBracketMatchNoBracket = squareBracketMatch.match(
            INSIDE_CROSSHAIR_VALUES_REGEX
          );
          if (
            squareBracketMatchNoBracket &&
            squareBracketMatchNoBracket.length > 1
          ) {
            const matches =
              squareBracketMatchNoBracket[1].split(/(\${[^}]+})/g);
            return (
              <span key={'legend-chunk' + i}>
                {matches.map((match, j) => {
                  if (match.match(/\${[^}]+}/)) {
                    // inside a ${...} block
                    const keyStr = match
                      .replace(/^\${\s*/, '')
                      .replace(/\s*}$/, '');
                    if (keyStr === 'x' && values.x) {
                      const keyPrefix =
                        type === 'line' ? 'x-axis-val' : 'legend-val';
                      return (
                        <strong key={`${keyPrefix} ${j}`}>
                          {formatXAxisValue(values.x.xAxis, values.x.val)}
                        </strong>
                      );
                    } else {
                      // eslint-disable-next-line no-prototype-builtins
                      const value = values.hasOwnProperty(keyStr)
                        ? displayValue(
                            (values as any)[keyStr] as string | undefined
                          )
                        : undefined;
                      return <strong key={'legend-val ' + j}>{value}</strong>;
                    }
                  } else {
                    // not inside of a ${...} block
                    if (match.match(/^\s+$/)) {
                      // drop whitespace
                      return <span key={'whitespace ' + j}></span>;
                    } else {
                      return <span key={'match ' + j}>{match}</span>;
                    }
                  }
                })}
              </span>
            );
          } else {
            return <span key={'empty ' + i}></span>;
          }
        } else {
          // outside of square bracket
          if (truncateOutsideOfCrosshairValues) {
            squareBracketMatch = truncateString(
              squareBracketMatch,
              maxLength,
              runNameTruncationType
            );
          }
          return !simpleVersion ? (
            <span key={'legend-chunk' + i} style={fontStyle}>
              {squareBracketMatch}
            </span>
          ) : null;
        }
      })}
    </span>
  );
}

export function containsCrosshairValues(legendTemplate: string) {
  const match = legendTemplate.match(INSIDE_CROSSHAIR_VALUES_REGEX);
  return match != null;
}

export function defaultCrosshairValues({
  isGrouped,
  isFullFidelity,
  isSmoothed,
  type,
}: {
  isGrouped: boolean;
  isFullFidelity: boolean;
  isSmoothed: boolean;
  type: PlotType;
}) {
  /* eslint-disable no-template-curly-in-string */

  if (type === 'pct-area') {
    return ' [[ ${x}: ${y} (${percent}%) ]]';
  } else if (type === 'stacked-area') {
    return ' [[ ${x}: ${y} ]]';
  } else if (type === 'bar') {
    if (isGrouped) {
      return ' [[ ${mean} σ ${stddev} (${min}, ${max}) ]]';
    } else {
      return ' [[ ${x} ]]';
    }
  } else {
    if (isGrouped && !isFullFidelity) {
      return ' [[ ${x}: ${mean} σ ${stddev} (${min}, ${max}) ]]';
    } else if (isGrouped && isFullFidelity) {
      return ' [[ ${x}: ${mean} (${min}, ${max}) ]]';
    } else if (isSmoothed) {
      return ' [[ ${x}: ${y} (${original})]]';
    } else {
      return ' [[ ${x}: ${y} ]]';
    }
  }
  /* eslint-enable no-template-curly-in-string */
}
export function legendTemplateFromFields({
  legendFields,
  isGrouped,
  isFullFidelity,
  isSmoothed,
  type,
}: {
  legendFields: string[];
  isGrouped: boolean;
  isFullFidelity: boolean;
  isSmoothed: boolean;
  type: PlotType;
}) {
  // Generate a default template from choices of fields
  const crosshairValues = defaultCrosshairValues({
    isGrouped,
    isFullFidelity,
    isSmoothed,
    type,
  });

  const chosenFieldsStr = legendFields
    .map(legendField => '${' + Run.rawConfigKeyDisplayName(legendField) + '}')
    .join(' ');

  return crosshairValues + ' ' + chosenFieldsStr;
}

export function legendTemplateToFancyLegendProps(
  legendTemplate: string,
  run: RunWithRunsetInfo,
  groupKeys: RunTypes.Key[],
  metricName: string,
  rootUrl?: string
) {
  /**
   * This is like parseLegendTemplate but instead of returning a string
   * returns a FancyLegendProps object for use in the legend on top of
   * PanelRunsLinePlot.
   * This is so we can do things like have the legend name hyperlink
   * to the associated run.
   * This removes everything inside of double square brackets since we don't use
   * this inside of our crosshair.
   */

  const squareBracketMatches = legendTemplate.split(ALL_CROSSHAIR_VALUES_REGEX);
  const fancySpanChildren: JSX.Element[] = [];
  squareBracketMatches.forEach(squareBracketMatch => {
    if (squareBracketMatch.match(INSIDE_CROSSHAIR_VALUES_REGEX)) {
      // fancySpanChildren.push(
      //  <span key={fancySpanChildren.length}>{squareBracketMatch}</span>
      // );
    } else {
      // Regions outside of square brackets
      const matches = squareBracketMatch.trim().split(/(\${[^}]+})/g);
      matches.forEach((match, i) => {
        if (match.match(/\${[^}]+}/)) {
          // matches a ${ }
          const keyStr = match.replace(/^\${\s*/, '').replace(/\s*}$/, '');
          const value = valueForTemplateKey(keyStr, run, groupKeys, metricName);
          fancySpanChildren.push(
            <span key={fancySpanChildren.length} className={`span-${keyStr}`}>
              {value}
            </span>
          );
        } else if (match.length > 0) {
          fancySpanChildren.push(
            <span key={fancySpanChildren.length}>{match}</span>
          );
        }
      });
    }
  });

  return {
    legend: fancySpanChildren,
    run,
    metricName,
    groupKeys,
    rootUrl,
  } as FancyLegendProps;
}

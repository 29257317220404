import {createSelector} from 'reselect';

import {RootState} from '../../../types/redux';
import {LayedOutPanel} from '../../../util/panelTypes';
import {PanelBankSectionConfigNormalized} from '../panelBankSectionConfig/types';
import {makePartSelector} from '../selectors';
import * as Types from './types';

export const makePanelPartsSelector = (ref: Types.Ref) => {
  return (state: RootState) => {
    const panelBankConfig = state.views.parts[ref.type][ref.id];
    return panelBankConfig.sectionRefs.flatMap(sectionRef => {
      const section = state.views.parts[sectionRef.type][sectionRef.id];
      return section.panelRefs.map(
        panelRef => state.views.parts[panelRef.type][panelRef.id]
      );
    });
  };
};

export const makeAllSectionsAndPanelsSelector = (
  panelBankConfigRef: Types.Ref
) => {
  return createSelector(
    makePartSelector(panelBankConfigRef),
    (state: RootState) => state.views.parts['panel-bank-section-config'],
    (state: RootState) => state.views.parts.panel,
    (panelBankConfig, sectionParts, panelParts) => {
      const sections: PanelBankSectionConfigNormalized[] = [];
      let panels: LayedOutPanel[] = [];

      panelBankConfig.sectionRefs.forEach(sectionRef => {
        const section = sectionParts[sectionRef.id];
        sections.push(section);

        const panelRefs = section.panelRefs;
        const sectionPanels = panelRefs.map(
          panelRef => panelParts[panelRef.id]
        );
        panels = panels.concat(sectionPanels);
      });

      return {
        sections,
        panels: [],
      };
    }
  );
};

export const makeNumPanelsSelector = (ref: Types.Ref, skip: boolean) => {
  return skip
    ? () => undefined
    : (state: RootState) => {
        const panelBank = state.views.parts[ref.type][ref.id];
        return panelBank.sectionRefs.reduce((numPanels, sectionRef) => {
          const section = state.views.parts[sectionRef.type][sectionRef.id];
          return numPanels + section.panelRefs.length;
        }, 0);
      };
};

export const makePanelsBySectionSelector = (ref: Types.Ref) => {
  return createSelector(
    makePartSelector(ref),
    (state: RootState) => state.views.parts['panel-bank-section-config'],
    (state: RootState) => state.views.parts.panel,
    (panelBankConfig, sectionParts, panelParts) => {
      return panelBankConfig.sectionRefs.map(sectionRef => {
        const section = sectionParts[sectionRef.id];
        const panels = section.panelRefs.map(
          panelRef => panelParts[panelRef.id]
        );
        return {
          sectionRef,
          panels,
        };
      });
    }
  );
};
